@import 'assets/scss/mixins';

.financeFail {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 660px;
    .icon {
        color: $mainWarring;
        font-size: 80px;
    }
    h1 {
        text-transform: uppercase;
        margin-top: 32px;
        text-align: center;
    }
    p {
        margin: 16px 0;
        font-weight: 400;
        color: $dark80;
        text-align: center;
        font-size: 16px;
    }
    a {
        color: inherit;
        text-decoration: underline;
        font-weight: bold;
    }
    .reference {
        padding: 24px 40px;
        background: $dark5;
        border-radius: 4px;
        width: 100%;
        text-align: center;
        color: $dark60;
        font-size: 14px;
        font-weight: 400;
    }
    .orderNumber {
        color: $dark1;
        font-size: 24px;
        font-weight: 500;
        font-family: $primary-font;
    }
    .button {
        margin-top: 32px;
    }
}
