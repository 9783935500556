@import 'assets/scss/mixins';

.root {
    padding: 16px;
    background-color: $dark10;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    span {
        font-family: $secondary-font;
    }
}

.price {
    font-weight: 500;
}
