@import 'assets/scss/mixins';

.wrapper {
    position: relative;
}

.overflow_wrapper {
    position: absolute;
    overflow: auto;
    height: auto;
    padding: 24px;
    z-index: 10;
    border-radius: 4px;
    background-color: $mainWhite;
    filter: drop-shadow(0px 8px 40px rgba(24, 23, 25, 0.2));
    -webkit-filter: drop-shadow(0px 8px 40px rgba(24, 23, 25, 0.2)); // for safari
}

.menu_popup {
    position: relative;
    //overflow: auto; // this is causing a problem (TM-631) - don't delete this in case the bug appear again
    @include scrollbar(4px, $dark20, transparent);
}

.arrow {
    position: absolute;
    top: -12px;
    left: 0;
    width: 10px;
    height: 10px;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-bottom: 12px solid $mainWhite;
    z-index: 15;
}

.arrow_bottom {
    transform: rotate(180deg);
    top: unset;
    bottom: -12px;
}
