@import 'assets/scss/mixins';

.overlay {
    align-items: flex-end;
}

.tooltipWrapper {
    i {
        transition: color 300ms ease-out;
        color: $dark40;
        font-size: 20px;
    }

    &:global(.active) {
        i {
            color: $mainAttention;
        }
    }
}

.tooltip {
    max-width: 280px !important;

    b,
    p {
        font-size: toRem(14);
        line-height: toRem(20);
    }

    b {
        font-weight: 700;
    }

    p {
        margin-bottom: 0.5rem;
    }
}

.modal_section {
    min-height: 264px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    overflow: unset;

    @include screen('mobile') {
        min-height: 200px;
        padding: 16px;
        gap: 16px;
    }

    .titleWrapper {
        display: flex;
        align-items: center;
        gap: 6px;
    }

    h6 {
        color: $dark5;
        font-style: normal;
        font-weight: 500;
        font-family: $primary-font;
        text-transform: uppercase;
        letter-spacing: 0.04em;

        @include screen('mobile') {
            font-size: toRem(22);
        }
    }

    .values_wrapper {
        display: flex;
        width: 100%;
        overflow: auto;
    }

    .values {
        display: flex;
        flex-direction: row;
        gap: 8px;
    }

    .item {
        min-width: 64px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $dark1;
        border-radius: 40px;
        color: $dark5;
        position: relative;
        text-transform: capitalize;
        border: 2px solid transparent;
        transition: all 200ms ease-in;

        @include screen('mobile') {
            min-width: 48px;
            height: 48px;
            font-family: $secondary-font;
            font-size: toRem(14);
            color: $dark40;
        }
    }

    .button {
        width: 100%;

        @include screen('mobile') {
            font-size: toRem(16);
        }
    }

    .close_icon {
        top: 37px;
        right: 37px;

        @include screen('mobile') {
            top: 16px;
            right: 16px;
        }
    }

    .color {
        width: 64px;
        height: 64px;

        span {
            width: 48px;
            height: 48px;
        }

        @include screen('mobile') {
            width: 48px;
            height: 48px;

            span {
                width: 32px;
                height: 32px;
            }
        }
    }

    .active_item {
        border: 2px solid $mainRed;

        @include screen('mobile') {
            color: $dark5;
        }

        .name {
            color: $mainWhite;
        }
    }

    .rullerTag {
        width: 64px;
        height: 64px;
        border-radius: 50%;

        @include screen('mobile') {
            width: 48px;
            height: 48px;
        }

        i {
            color: $dark5;

            @include screen('mobile') {
                color: $dark40;
            }
        }
    }

    .color_picker {
        box-sizing: border-box;
        cursor: pointer;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid transparent;
        transition: all 200ms ease-in;
        width: 64px;
        height: 64px;

        @include screen('mobile') {
            width: 46px;
            height: 46px;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }

    .active {
        border: 2px solid $mainRed;
        padding: 4px;

        img {
            width: 48px;
            height: 48px;

            @include screen('mobile') {
                width: 32px;
                height: 32px;
            }
        }
    }

    .bike_card {
        @include screen('mobile') {
            min-width: 156px;
            max-width: 156px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;

            div:first-child {
                width: 104px;
            }
        }
    }
}

.colorPickerModal {
    padding: 32px 0 32px 32px;

    @include screen('mobile') {
        padding: 16px;
    }
}
