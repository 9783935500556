@import 'assets/scss/mixins';

.payment_status {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $dark3;
    flex: 1;

    .infos {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .success_title {
            text-align: center;
        }

        .error {
            font-size: 80px;
            color: $mainRed;
        }

        .error_title {
            margin-top: 32px;
        }

        .error_message {
            margin: 16px 0 32px;
            color: $dark80;
        }

        @include screen('mobile') {
            max-width: 295px;
        }
    }

    img {
        min-height: 160px;
        max-height: 320px;
        margin-bottom: 32px;

        @include screen('mobile') {
            width: 220px;
            min-height: 126px;
        }
    }

    h1 {
        text-transform: uppercase;

        @include screen('mobile') {
            text-align: center;
            font-size: toRem(28);
            line-height: toRem(34);
        }
    }

    p {
        margin: 16px 0;
        font-weight: 400;
        color: $dark80;
        text-align: center;
        font-size: 16px;
        line-height: 26px;
    }

    a {
        color: inherit;
        text-decoration: underline;
        font-weight: bold;
    }

    .reference {
        padding: 24px 40px;
        background: $dark5;
        border-radius: 4px;
        width: 100%;
        text-align: center;
        color: $dark60;
        font-size: 14px;
        font-weight: 400;
    }

    .orderNumber {
        color: $dark1;
        font-size: 24px;
        font-weight: 500;
        font-family: $primary-font;
        margin-top: 10px;
    }

    .button {
        margin-top: 32px;
        @include screen('mobile') {
            height: 54px;
            width: 100%;
        }
    }
}
