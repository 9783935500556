@import 'assets/scss/mixins';

.root {
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;

    span {
        cursor: pointer;
        font-family: $secondary-font;
        font-size: toRem(14);
        line-height: toRem(20);
        text-decoration: underline;
    }
}

.expertCheckoutModalInput {
    margin-top: 12px;
}

.buttonsWrapper {
    margin-top: 24px;
    display: flex;
    gap: 12px;
    justify-content: flex-end;

    button {
        padding: 12px 24px;
        min-width: 110px;
    }
}
