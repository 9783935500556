@import 'assets/scss/mixins';

.root {
    padding: 10px 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    background-color: $dark5;
    color: $dark1;
    border-radius: 20px;
    font-family: 'Mulish';
    font-size: toRem(14);
    line-height: toRem(20);

    i {
        cursor: pointer;
        font-size: 18px;
    }
}
