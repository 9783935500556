@import 'assets/scss/mixins';

.root {
    display: flex;
    flex-direction: column;
}

.header {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include screen('large-tablet') {
        justify-content: flex-start;
        gap: 6px;

        h6:before {
            content: '— ';
        }
    }
}

.subtitle {
    font-family: $primary-font;
    font-weight: 500;
    font-size: toRem(14);
    letter-spacing: 0.1rem;
    line-height: toRem(20);
    color: $dark1;
    margin-bottom: 8px;
}

.price_label {
    @extend .subtitle;
    color: $dark60;
}

.sectionWrapper {
    @include scrollbar(3px, $dark20, transparent);
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    gap: 8px;
    padding-bottom: 5px;

    @include screen('large-tablet') {
        flex-direction: row;
        gap: 16px;
    }

    @include screen('mobile') {
        flex-direction: column;
        overflow-x: auto;
    }
}

.section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px;
    background-color: $dark5;
    border-radius: 4px;

    @include screen('large-tablet') {
        gap: 16px;
    }
}

.label {
    color: $dark60;
}

.types {
    display: flex;
    gap: 8px;

    @include screen('large-tablet') {
        flex-direction: column;
    }

    @include screen('mobile') {
        flex-direction: row;
    }
}

.color_type {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    border-radius: 32px;
    background-color: $mainWhite;
    padding: 8px 16px 8px 8px;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 300ms ease-in;

    @include screen('large-tablet') {
        padding: 20px;
    }

    @include screen('mobile') {
        padding: 8px 16px 8px 8px;
    }

    h6 {
        color: $dark60;
    }

    i {
        color: $dark60;
    }
}

.color_type_active {
    border: 2px solid $mainRed;

    h6 {
        color: $dark1;
    }

    i {
        color: $dark1;
    }
}

.colors {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 8px;

    @include screen('mobile') {
        display: flex;
        flex-wrap: wrap;
    }
}

.color {
    @include screen('large-tablet') {
        width: 64px;
        height: 64px;

        @include screen('mobile') {
            width: 40px;
            height: 40px;
        }

        span {
            width: 52px;
            height: 52px;

            @include screen('mobile') {
                width: 28px;
                height: 28px;
            }
        }
    }
}

.button {
    margin-top: 24px;

    @include screen('large-tablet') {
        margin-right: 32px;
    }
}
