@import 'assets/scss/mixins';

.carousel_arrow {
    width: 48px;
    height: 48px;
    background-color: $mainWhite;
    box-shadow: 0 8px 16px rgba(24, 23, 25, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;

    i {
        color: $mainRed;
    }
}

.disabled {
    opacity: 0.5;
    pointer-events: none;
}
