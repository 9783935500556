@import 'assets/scss/mixins';

.contactCover {
    background: linear-gradient(86.62deg, rgba(24, 23, 25, 0.7) 0%, rgba(24, 23, 25, 0) 181.16%),
        url('/assets/images/contact_cover.png');
    background-size: cover;
    background-position: center;
    min-height: 160px;

    display: flex;
    align-items: flex-end;
    padding: 24px 48px;

    @include screen('mobile') {
        padding: 16px;
    }

    h3 {
        color: $mainWhite;
        text-transform: uppercase;
        font-weight: 500;
    }
}
