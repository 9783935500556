@import 'assets/scss/mixins';

.form {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.input {
    label {
        font-family: $secondary-font;
        color: $dark2;
    }
}

.labelWrapper {
    margin-bottom: 4px;
}

.cityWrapper {
    display: flex;
    gap: 16px;
}

.postalCode {
    flex: 1;
}

.city {
    flex: 2.5;
}
.error {
    text-align: right;
}

.modalButton {
    margin-left: auto;
    margin-top: 8px;
    max-height: 66px;
}

// PHONE INPUT STYLES

:global(.react-tel-input) {
    :global(.form-control) {
        width: 100%;
        height: auto;
        padding: 16px;
        padding-left: 72px;
        border: 1px solid $dark10;
        border-radius: 4px;
        line-height: toRem(22);
    }

    :global(.flag-dropdown) {
        background-color: $mainWhite;
        border: 1px solid $dark10;
        border-radius: 4px 0 0 4px;

        &:global(.open) {
            border-radius: 4px 0 0 4px;
            :global(.selected-flag) {
                border-radius: 4px;
            }
        }
    }

    :global(.selected-flag) {
        padding-left: 16px;
        width: 54px;
    }

    :global(.country-list) {
        border: 1px solid $dark10;
        border-radius: 4px;
        box-shadow: none;
        user-select: none;
    }
}
