@import 'assets/scss/mixins';

.exit_intent {
    h3 {
        text-transform: uppercase;
        letter-spacing: 0.72px;
    }

    h6 {
        color: $dark60;
        letter-spacing: 0.14px;
    }

    .button {
        width: 100%;
    }

    .first_step {
        display: flex;
        flex-direction: column;
    }

    .title {
        margin-bottom: 8px;
    }

    .subtitle {
        margin-bottom: 24px;
    }

    .title_wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .second_step {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .code_wrapper {
            background-color: $dark5;
            border-radius: 4px;
            padding: 24px;
            text-align: center;

            .code_text {
                font-size: toRem(24);
                line-height: toRem(30);
                font-weight: 500;
                margin-bottom: 8px;
                font-variant-numeric: tabular-nums;
            }

            .link_text {
                color: $dark60;
                font-size: toRem(14);
                font-family: $secondary-font;

                span {
                    color: $dark1;
                    font-variant-numeric: tabular-nums;
                }
            }
        }

        .email_wrapper {
            display: flex;
            flex-direction: column;
            gap: 12px;

            .email_label {
                text-transform: uppercase;
            }

            button {
                margin-top: 24px;
            }
        }

        .loader_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 530px;
        }
    }

    .third_step {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}
