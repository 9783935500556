@import 'assets/scss/mixins';

.select {
    font-family: $secondary-font;

    :global(.react-select__control) {
        cursor: pointer;
        border: 1px solid $dark10;
        padding: 16px;
        font-size: toRem(14);
        line-height: toRem(22);
        min-height: unset;
        box-shadow: none;

        &:hover {
            border-color: $dark40;
        }
        &:focus-within {
            border-color: $dark1;
        }
    }

    :global(.react-select__input-container),
    :global(.react-select__value-container),
    :global(.react-select__indicator) {
        margin: 0;
        padding: 0;
    }

    :global(.react-select__placeholder) {
        color: $dark40;
    }

    :global(.react-select__indicator) {
        color: $dark1;
    }

    :global(.react-select__menu) {
        box-shadow: none;
        border: 1px solid $dark1;
    }

    :global(.react-select__menu-list) {
        padding: 0;
        border-radius: 4px;
    }

    :global(.react-select__option) {
        cursor: pointer;
        color: $dark60;
        background-color: $mainWhite;
        font-size: toRem(14);
        line-height: toRem(22);
        padding: 8px 16px;

        &:active {
            color: $dark1;
            background-color: $dark10;
        }
    }

    :global(.react-select__option--is-focused) {
        background-color: $dark5;
        color: $dark1;
    }

    &.error {
        :global(.react-select__control) {
            border: 1px solid $mainWarring;
        }
    }
}

.labelWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 4px;

    i {
        color: $mainAttention;
        font-size: 20px;
        cursor: pointer;
    }
}

.label {
    color: $dark2;
    display: block;
    font-family: $secondary-font;
    color: $dark2;
    font-size: toRem(14);
    line-height: toRem(22);
}

.errorMessage {
    text-align: right;
}
