@import 'assets/scss/mixins';

.inputs {
    margin-top: 16px;
    display: flex;
    gap: 16px;

    > div {
        flex: 1;
        max-width: calc(50% - 8px);
        font-family: $secondary-font;
    }

    @include screen('small-tablet') {
        flex-direction: column;
        > div {
            max-width: unset;
        }
    }
}
.labelWrapper {
    label {
        color: $dark1;
        display: block;
        font-size: toRem(16);
        line-height: toRem(24);
        text-transform: uppercase;
        font-family: $primary-font;
    }
}
.buttons {
    display: flex;
    justify-content: space-between;
}
.next {
    margin-top: 32px;
    padding: 16px 24px;
}
.error {
    color: $mainRed;
    font-size: 12px;
}
