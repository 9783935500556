@import 'assets/scss/mixins';

.checkoutHero {
    background: $mainBackground;
    height: 510px;
    z-index: 1;
    color: white;
    margin-bottom: 116px;

    @include screen('large-tablet') {
        height: 580px;
        margin-bottom: 40px;

        &.remove_price {
            height: 520px;
        }
    }

    @include screen('mobile') {
        height: 512px;
        margin-bottom: 0;

        &.remove_price {
            height: 420px;
        }
    }

    h1 {
        font-weight: 500;
        text-transform: uppercase;
        margin-top: 16px;
        margin-bottom: 16px;

        @include screen('mobile') {
            font: 500 28px/32px Gentona, sans-serif;
        }
    }

    h2 {
        color: $dark40;
        text-transform: uppercase;

        @include screen('mobile') {
            font: 500 24px/32px Gentona, sans-serif;
        }
    }

    p {
        font-size: 14px;
        color: $dark40;

        span {
            color: $dark5;
        }
    }

    .logo {
        margin-top: 48px;
        margin-bottom: 80px;

        @include screen('large-tablet') {
            margin-bottom: 40px;
        }

        @include screen('mobile') {
            margin-top: 20px;
            margin-bottom: 24px;
        }
    }

    .container {
        max-width: 1180px;
        margin: 0 auto;
        padding: 0 16px;
        position: relative;

        @include screen('large-tablet') {
            padding: 0 80px;
        }

        @include screen('tablet') {
            padding: 0 40px;
        }

        @include screen('mobile') {
            padding: 0 24px;
        }
    }

    .bikeImageWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .bikeImage {
        max-height: 340px;
        margin-bottom: -112px;
        display: block;
        margin-top: -12px;

        @include screen('large-tablet') {
            max-height: 212px;
            margin: 16px 0;
        }

        @include screen('mobile') {
            max-height: 160px;
            margin: 24px 0;
        }
    }

    .priceContainer {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        @include screen('large-tablet') {
            flex-direction: row;
            //align-items: flex-start;
            justify-content: space-between;
            width: 100%;
        }

        @include screen('mobile') {
            flex-direction: column;
            align-items: flex-start;
        }

        .total_price_section {
            display: flex;
            color: $dark20;
            width: max-content;

            .total_amount {
                margin-left: 4px;
            }

            @include screen('large-tablet') {
                flex-direction: column;
                align-items: flex-end;

                .total_amount {
                    margin-left: unset;
                }
            }

            @include screen('mobile') {
                flex-direction: row;
                width: 100%;
                justify-content: space-between;
            }
        }
    }

    .price {
        color: white;

        @include screen('mobile') {
            font: 500 22px/28px Gentona, sans-serif;
        }

        .per_month {
            font: 500 14px/24px Mulish, san-serif;
            color: $dark20;
            margin-left: 4px;
            text-transform: lowercase;
        }
    }

    .total {
        color: $dark40;
    }

    .title_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    //@include screen('mobile') {
    //    height: 360px;
    //    h1 {
    //        margin: 12px 0;
    //    }
    //    .logo {
    //        margin-bottom: 32px;
    //    }
    //    .bikeImage {
    //        max-height: 180px;
    //        margin-top: 32px;
    //    }
    //}
}
