@import 'assets/scss/mixins';

.rating {
    margin-top: -6px;
    i {
        color: $dark20;
        font-size: 16px;
    }
}

$start: 1;
$end: 5;

@for $i from $start through $end {
    .rate-#{$i} {
        i:nth-child(-n + #{$i}) {
            color: orange;
        }
    }
}
