@import 'assets/scss/mixins';

.root {
    cursor: pointer;
    width: max-content;
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
}

.radio {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    background-color: $dark20;
    border-radius: 20px;

    .inner {
        display: none;
        box-sizing: content-box;
        background-color: transparent;
        border-radius: 20px;
        width: 12px;
        height: 12px;
        border: 4px solid $dark3;
    }

    &.active {
        background-color: $mainRed;

        .inner {
            display: block;
        }
    }
}

.label {
    cursor: pointer;
    display: inline-block;
    font-family: $secondary-font;
    font-size: toRem(14);
    line-height: toRem(22);

    > * {
        display: inline-block;
    }
}
