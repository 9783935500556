@import 'assets/scss/mixins';

.bike_part_wrapper {
    display: flex;
}

.bike_part {
    width: 152px;
    height: 116px;
    padding: 12px;
    background-color: $dark5;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    position: relative;
    outline: none;
    margin-right: 8px;
    z-index: 50;
    cursor: pointer;
    transition: all 200ms ease-in;
    border: 2px solid transparent;

    &.active_bike_part {
        border: 2px solid $mainRed;

        @include screen('large-tablet') {
            border: 2px solid transparent;
        }
    }

    &:hover {
        background-color: $dark10;

        @include screen('large-tablet') {
            background-color: $dark5;
        }
    }

    .part_image {
        width: 256px;
        height: 48px;
        object-fit: contain;
    }

    .name {
        @include line-clamp(2);
        display: block;
        width: 100%;
        text-align: center;
    }

    .variations {
        position: absolute;
        top: 0;
        right: 0;
        width: 28px;
        height: 28px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $dark1;
        border-radius: 50%;
        transition: all 200ms ease-in;

        i {
            color: $mainRed;
            font-size: 20px;
        }
    }

    .add_remove_btn {
        text-transform: uppercase;
        color: $mainRed;
        font-weight: 500;
        font-family: $primary-font;

        &.add_remove_btn_active {
            color: $dark60;
        }
    }

    &.disabled {
        opacity: 0.6;
        pointer-events: none;
    }

    @include screen('mobile') {
        width: 144px;
        min-height: 104px;
    }
}

.variants {
    max-height: 560px;
    display: flex;
    gap: 8px;
    flex-direction: column;

    @include screen('large-desktop') {
        max-height: 365px;
    }
}

.tooltip {
    display: flex;
    flex-direction: column;

    .price {
        color: $dark60;
    }

    .description {
        @extend .price;
        margin-top: 8px;
    }
}

.parts_popup {
    bottom: 132px;
    padding: 24px 6px 8px 24px;
    z-index: 15;
    cursor: auto;
    opacity: 0;
    animation: slideUp 150ms ease-in-out forwards;

    .parent {
        text-transform: uppercase;
        font-family: $primary-font;
        color: $dark1;
        letter-spacing: 0.1rem;
        margin-bottom: 8px;
    }
}

.container_with_variants {
    overflow: auto;
    @include scrollbar(4px, $dark20, transparent);
    padding-right: 12px;
    max-height: 474px;
}

.popup_arrow {
    top: -132px;

    &.popup_arrow_to_left {
        left: 516px;
    }

    &.popup_arrow_to_right {
        left: 64px;
    }

    &.popup_arrow_to_center {
        left: 335px;
    }
}

.divider {
    position: relative;
    width: 56px;
    background-color: $mainWhite;
    padding: 38px 27px;
    margin-right: 8px;
    border-radius: 4px;

    .line {
        width: 2px;
        height: 40px;
        background-color: $dark10;
    }
}

.transparent_section {
    width: 100%;
    position: absolute;
    height: 50px;
    left: -24px;
    bottom: -66px;
}

@keyframes slideUp {
    from {
        opacity: 0;
        transform: translateY(50px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
