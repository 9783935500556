@import 'assets/scss/mixins';

.container {
    max-width: 1240px;
    width: 100%;
    margin: 0 auto;
    padding: 30px;
    display: flex;
    flex-direction: row;
    gap: 40px;

    @include screen('large-tablet') {
        flex-direction: column-reverse;
        gap: 24px;
    }

    @include screen('mobile') {
        padding: 22px 16px 32px;
    }
}

.checkoutWrapper {
    flex: 1;
}

.accordionWrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.titleDisabled {
    color: $dark40;
}

.orderSummary {
    flex: 0 0 380px;

    @include screen('large-tablet') {
        flex: 1;
    }
}
