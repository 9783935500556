@import 'assets/scss/mixins';

.root {
    margin-top: 32px;

    @include screen('mobile') {
        margin-top: 24px;
    }
}

.description {
    margin-bottom: 12px;
    color: $dark60;
    font-size: toRem(14);
    line-height: toRem(20);
    white-space: pre-wrap;
}

.subtitle {
    margin-bottom: 12px;

    label {
        color: $dark1;
        font-family: $primary-font;
        font-size: toRem(16);
        line-height: toRem(24);
    }
}

.addresses {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 16px;
    margin-top: 12px;

    @include screen('mobile') {
        grid-template-columns: 1fr;
    }
}

.billingLabel {
    margin-top: 32px;

    @include screen('mobile') {
        margin-top: 24px;
    }
}

.billingCheckbox {
    margin-top: 12px;
}

.button {
    margin-top: 32px;
    padding: 16px 24px;

    @include screen('mobile') {
        width: 100%;
        margin-top: 24px;
    }
}
