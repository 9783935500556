@import 'assets/scss/mixins';

.root {
    margin-top: 32px;

    @include screen('mobile') {
        margin-top: 24px;
    }
}

.deliveryMethods {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @include screen('mobile') {
        grid-template-columns: 1fr;
    }
}

.homeAddress {
    margin-top: 24px;
    p {
        font-size: toRem(16);
        line-height: toRem(26);
    }
}

.button {
    margin-top: 32px;
    padding: 16px 24px;
    min-width: 87px;

    @include screen('mobile') {
        margin-top: 24px;
        width: 100%;
    }
}
