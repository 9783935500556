@import 'assets/scss/mixins';

.backIcon {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;

    @include screen('mobile') {
        left: 16px;
    }
}
