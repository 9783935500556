@import 'assets/scss/mixins';

.color {
    height: 40px;
    width: 40px;
    border: 1px solid #000000;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 300ms ease-in;
    z-index: 10;

    &:hover {
        transform: scale(0.75);
    }

    span {
        width: 28px;
        height: 28px;
        margin: 4px;
        border-radius: 50%;
        border: 1px solid #000000;
        transition: all 300ms ease-in;
    }

    &.active_color {
        border: 2px solid $mainRed !important;

        &:hover {
            transform: scale(1);
        }
    }

    &.light_mode {
        border: 1px solid $dark20;
        z-index: 1;

        span {
            border: 1px solid $dark20;
        }
    }

    &.smaller_color {
        height: 30px;
        width: 30px;

        span {
            margin: 2px;
            height: 22px;
        }
    }
}
