@import 'assets/scss/mixins';

.pre_configurations_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 24px;

    h5 {
        margin-bottom: 16px;
    }
}

.pre_configurations {
    display: grid;
    //grid-template-columns: 1fr 1fr;
    grid-auto-rows: 1fr;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));
}

.more_bikes {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $darkClick;
    border-radius: 4px;
    cursor: pointer;
    position: relative;
    z-index: 11;
    border: 2px solid transparent;
    transition: all 200ms ease-in;

    &:hover {
        background-color: $dark80;
    }

    &:active {
        background-color: $dark60;
    }

    h5 {
        text-decoration: underline;
        margin: 0;
        pointer-events: none;
        user-select: none;
    }
}

.more_bikes_active {
    border: 2px solid $mainRed;
}

.popup {
    right: -88px;
    bottom: 24px;
    z-index: 16;
}

.popup_arrow {
    left: unset;
    right: -10px;
    top: -24px;
}

.subtitle {
    font-family: $primary-font;
    font-weight: 500;
    font-size: toRem(14);
    letter-spacing: 0.1rem;
    line-height: toRem(20);
    color: $dark1;
    margin-bottom: 8px;
    text-transform: uppercase;
}

.choose_preconfiguration {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;
}
