@import 'assets/scss/mixins';

.backIcon {
    cursor: pointer;
    position: absolute;
    top: 24px;
    left: 24px;

    @include screen('mobile') {
        top: 16px;
        left: 16px;
    }
}

.body {
    padding: 24px 48px 48px;

    @include screen('mobile') {
        padding: 24px 16px 32px;
    }

    h5 {
        text-transform: uppercase;
        font-weight: 500;
    }
}

.options {
    margin-top: 16px;
    display: flex;
    gap: 24px;
}
