@import 'assets/scss/mixins';

.frame_size {
    width: auto;
    background-color: $dark1;
    border-radius: 20px;
    padding: 0 10px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 200ms ease-in;

    &.smaller {
        height: 30px;
    }

    &:hover {
        background-color: $dark140;

        i {
            color: $mainWhite;
        }
    }

    @include screen('mobile') {
        height: 48px;
        border-radius: 24px;
    }

    i {
        color: $dark40;
        margin-bottom: 3px;
        transition: all 200ms ease-in;
    }
}
