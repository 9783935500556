@import 'src/assets/scss/mixins.scss';

.continue_config_modal {
    display: flex;
    flex-direction: column;
    gap: 24px;

    h3 {
        text-transform: uppercase;
    }

    h6 {
        letter-spacing: 0.14px;
    }

    .title_wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .ghost_button {
        border-color: $mainRed;
        color: $mainRed;
    }
}
