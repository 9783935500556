@import 'assets/scss/mixins';

.bike3d__loading-screen {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    background: rgba(255, 255, 255, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 800ms opacity;
    top: 0;
    left: 0;
    z-index: 15;

    h2 {
        margin-bottom: 16px;
        text-align: center;
    }
}

#loadingScreen {
    opacity: 1;
    background: #f8f7f9;
}

.loading-screen__items {
    display: flex;
    justify-content: center;
    align-items: center;

    h2 {
        text-transform: uppercase;
    }
}

.image-wrapper {
    margin-bottom: 32px;
    height: 160px;

    img {
        width: 300px;
        height: 160px;
        object-fit: contain;
        mix-blend-mode: multiply;
    }
}

.loading-screen__checkbox {
    color: white;
    position: absolute;
    bottom: 16px;
    left: 16px;

    label {
        color: white;
    }
}

.d-col {
    flex-direction: column;
}

.loading-slider {
    width: 300px;
    height: 8px;
    background-color: $dark20;
    border-radius: 100px;
    overflow: hidden;

    .progress {
        width: 0;
        background: $mainRed;
        height: 8px;
        transition: all 500ms;
    }
}

.wheel-img,
video {
    width: 100px;
    height: 100px;
}

#config-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    background: rgba(255, 255, 255, 0.1);
    cursor: progress;
}

.sizes-loader {
    background-color: rgba(255, 255, 255, 0.6);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading-text {
    font-family: 'Mulish';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    text-align: center;
    letter-spacing: 0.01em;
    color: $dark80;
    margin-bottom: 32px;
    max-width: 500px;
}

@media only screen and (max-width: 1024px) {
    .wheel-img {
        width: 85px;
        height: 85px;
    }
}

@media only screen and (max-width: 375px) {
    .wheel-img {
        width: 75px;
        height: 75px;
    }

    .loading-text {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #727075;
        margin-top: 12px;
    }
}

.loading-screen__items-control {
    height: 3.2em;
    width: 3.2em;
}

.opacity-0 {
    opacity: 0;
}

.fade-bg {
    background: rgba(0, 0, 0, 0.674);
}

.cursor-pointer {
    cursor: pointer;
}

.main-desc {
    font-size: 1.5em;
    color: white;
    white-space: pre;
    line-height: 0.9em;

    @include screen('mobile') {
        font-size: 0.7em;
    }
}

.sub-desc {
    font-size: 1.1em;
    color: white;
    white-space: pre;
    line-height: 1.8em;

    @include screen('mobile') {
        font-size: 0.8em;
    }
}

.controls-screen__group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 4em;

    @include screen('mobile') {
        margin: 0 1.5em;
    }
}

.control-screen__btns {
    position: absolute;
    z-index: 2;
    bottom: 20px;
    right: 32px;
    display: flex;
    flex-direction: column;
}

.control-screen__btn {
    width: 40px;
    height: 40px;
    padding: 8px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(149, 149, 149, 0.681);
}

.control-screen__btn-img {
    width: 100%;
    height: auto;
    opacity: 0.8;
}

.mt-1 {
    margin-top: 10px;
}

@-webkit-keyframes spin-me {
    0% {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateZ(360deg);
        transform: rotateZ(360deg);
    }
}

@keyframes spin-me {
    0% {
        -webkit-transform: rotateZ(0deg);
        transform: rotateZ(0deg);
    }
    100% {
        -webkit-transform: rotateZ(360deg);
        transform: rotateZ(360deg);
    }
}

.thing-to-spin {
    -webkit-animation-name: spin-me;
    animation-name: spin-me;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-duration: 3s;
    animation-duration: 3s;
    -webkit-animation-timing-function: ease-in-out;
    animation-timing-function: ease-in-out;
}

.img-preanimation {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: top 0.6s ease 0s;
}

.animation-droptop {
    position: absolute;
    width: 100%;
    top: -100%;
    left: 0;
    height: 100%;
    background: $mainRed;
    z-index: 4;
    transition: top 2.5s ease 0s;
}

.flash {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $mainWhite;
    opacity: 0;
    animation: flash ease-in 1.7s;
}

@keyframes flash {
    0% {
        opacity: 1;
        z-index: 123;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 0;
        z-index: -1;
    }
}
