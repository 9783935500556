@import 'assets/scss/mixins';

.labelWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 4px;

    i {
        color: $mainAttention;
        font-size: 20px;
        cursor: pointer;
    }
}

.label {
    font-family: $secondary-font;
    color: $dark1;
    display: block;
    font-size: toRem(14);
    line-height: toRem(22);
}
