@import 'src/assets/scss/mixins.scss';

.checkoutHeader {
    background-color: $dark3;
}

.container {
    max-width: 1240px;
    margin: 0px auto;
    padding: 0 30px;
    display: flex;
    flex-direction: row;
    gap: 40px;

    @include screen('mobile') {
        padding: 0 16px;
    }
}

.logo {
    justify-self: flex-start;
    height: 100%;
}

.checkoutLogo {
    line-height: 0;
    padding: 48px 0px 18px;
    display: inline-block;
    transition: all 150ms ease-in-out;

    @include screen('mobile') {
        padding: 20px 16px 18px;
        display: flex;
        align-items: center;

        img {
            max-width: 140px;
        }
    }
    &.twinnerLogo {
        img {
            width: 200px;
        }
    }
}

.headerContent {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-top: 24px;
    padding-right: 40px;

    @include screen('mobile') {
        padding-top: 0;
        padding-right: 16px;
    }
}

.buttons {
    display: flex;
    gap: 32px;
    max-height: 35px;

    @include screen('mobile') {
        gap: 24px;
    }
}
