@import 'assets/scss/mixins';

.color_picker_wrapper {
    position: relative;
    z-index: 10;
}

.color_picker {
    cursor: pointer;
    border-radius: 50%;
    border: 2px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 40px;
    max-height: 40px;
    transition: all 200ms ease-in;

    &.smaller {
        max-width: 30px;
        max-height: 30px;
    }

    &:hover {
        transform: scale(0.9);
    }
}

.active {
    border: 2px solid $mainRed;
    padding: 4px;
    width: 40px;
    height: 40px;

    &.smaller {
        padding: 2px;
    }

    &:hover {
        transform: scale(1);
    }
}

.menu_popup {
    left: -250px;
    top: 55px;
    z-index: 15;
    max-height: 406px;
    @include scrollbar(4px, $dark20, trasparent);
}

.menu_popup_arrow {
    right: -12px;
    top: 43px;
    left: unset;
}
