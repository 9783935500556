@import 'assets/scss/mixins';

.option {
    padding: 8px 16px;
    border: 2px solid $dark60;
    flex: 1;
    font-size: 14px;
    transition: all 150ms ease-in-out;
    margin-left: -2px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    .popular_option {
        display: flex;
        flex-direction: column;

        label {
            margin: 0;
            cursor: pointer;
            width: fit-content;
        }
    }

    span {
        transition: all 150ms ease-in-out;
    }

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        // border-right: none;
        margin-left: 0;
    }

    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        // border-left: none;
    }

    label {
        background-color: $mainRed;
        color: $mainWhite;
        padding: 2px 4px;
        border-radius: 4px;
        font-size: 10px;
        line-height: 10px;
        margin-left: 8px;
        text-transform: uppercase;
    }

    .optionPrice {
        font-family: $secondary-font;
    }

    &:hover {
        cursor: pointer;
    }

    &.selected {
        border-color: $mainGreen;
        background-color: rgba(207, 236, 214, 0.5);
        z-index: 1;

        span {
            color: $mainGreen;
        }
    }

    @include screen('small-tablet') {
        margin-left: 0;
        margin-top: -2px;

        &:first-child {
            border-top-right-radius: 4px;
            border-bottom-left-radius: 0;
            margin-top: 0;
        }
        &:last-child {
            border-top-right-radius: 0;
            border-bottom-left-radius: 4px;
        }
    }
}
