@import './reset';
@import './fonts';
@import './mixins';
@import './reboot';
@import './typography';
@import './icons';
@import '~slick-carousel/slick/slick.scss';
@import '~slick-carousel/slick/slick-theme.scss';

html {
    height: 100%;
}
body,
html,
#root {
    display: flex;
    flex-direction: column;
    flex: 1;
}
body {
    padding-bottom: env(safe-area-inset-bottom);
}

i[class^='icon-'] {
    font-size: 24px;
    line-height: 0;
}

// Hide number input field arrows
input[type='number'] {
    -moz-appearance: textfield;
}

// Hide number input field arrows
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

// Set default font for input and textarea
input,
textarea {
    font-family: $secondary-font;
}
.slideUp {
    bottom: 20px !important;
    transition: all 500ms ease-in;
}
