@import 'src/assets/scss/mixins.scss';
@import 'src/assets/scss/icons.scss';

.checkboxWrapper {
    display: flex;
    align-items: center;
    gap: 12px;

    input[type='checkbox'] {
        -webkit-appearance: none;
        appearance: none;
        width: 24px;
        height: 24px;
        border: 2px solid $dark40;
        border-radius: 4px;
        outline: none;
        cursor: pointer;

        &.checked {
            background-color: $mainRed;
            border: 2px solid $mainRed;
            position: relative;

            &::before {
                font-family: 'icons';
                content: map-get($icons-map, 'check');
                font-size: 20px;
                color: $mainWhite;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &:disabled {
            border-color: #c0c0c0;
            background-color: #c0c0c0;
            cursor: not-allowed;
            + {
                span {
                    color: #c0c0c0;
                }
            }
        }
    }

    label {
        font-family: $secondary-font;
    }
}
