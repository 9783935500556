@import 'assets/scss/mixins';

.coupon {
    margin-top: 16px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 6px;
    color: $dark20;
    cursor: pointer;

    span {
        color: $dark20;
        cursor: pointer;
        font-family: $secondary-font;
        font-size: toRem(14);
        line-height: toRem(20);
        text-decoration: underline;
    }
}

.tooltipWrapper {
    max-width: 260px;

    i {
        transition: color 300ms ease-out;
        color: $dark40;
        font-size: 20px;
    }

    &:global(.active) {
        i {
            color: $mainAttention;
        }
    }
}

.tooltip {
    max-width: 260px;
}

.couponInput {
    margin-top: 8px;
    background-color: $mainWhite;
    border-radius: 4px;
}

.submit {
    cursor: pointer;
    color: $mainRed;
}
