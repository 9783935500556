@import 'assets/scss/mixins';

.financeStatus {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: $dark3;
    flex: 1;
}
