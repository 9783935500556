@import 'assets/scss/mixins';

.fullDescription {
    p {
        font-size: toRem(16);
        line-height: toRem(20);
        margin: 10px 0;
    }
    i {
        font-style: italic;
    }
    b,
    strong {
        font-weight: bold;
    }
    ul {
        list-style-type: disc;
        margin-left: 20px;
        font-family: $secondary-font;

        li {
            font-weight: 400;
        }
    }
    blockquote {
        padding: 10px;
        background-color: $dark5;
        border-left: 2px solid $mainRed;
        p {
            margin: 0 0 8px;
        }
    }
}
