@import 'assets/scss/mixins';

.shortDescription p {
    font-family: $secondary-font;
    font-size: toRem(14) !important;
    line-height: toRem(20);
    color: $dark60;
    display: inline;
    @include line-clamp(2);
}
.read_more {
    text-decoration: underline;
    margin-left: 5px;
    font-style: normal;
    font-family: $secondary-font;
    font-size: toRem(14);
    line-height: toRem(14);
    transition: all 150ms ease-in-out;
    &:hover {
        color: $mainRed;
    }
}
