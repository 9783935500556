@import 'assets/scss/mixins';

.preconfiguration_modal {
    display: flex;
    flex-direction: column;

    .text {
        display: flex;
        flex-direction: column;
    }

    .title {
        letter-spacing: 0.03em;
        text-transform: uppercase;
        margin-bottom: 8px;

        @include screen('mobile') {
            max-width: 90%;
        }
    }

    .subtitle {
        letter-spacing: 0.01em;
        color: $dark60;

        strong {
            color: $dark1;
        }
    }

    .images {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 28px 0;
        gap: 16px;

        //.img {
        //    max-width: 105px;
        //    max-height: 64px;
        //
        //    img {
        //        width: 100%;
        //        height: 100%;
        //        object-fit: cover;
        //    }
        //}
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .exit_button {
            cursor: pointer;
            font-family: $primary-font;
            letter-spacing: 0.1em;
            text-align: center;
            text-decoration: underline;
            transition: color 200ms ease-in;

            &:hover {
                color: $dark80;
            }
        }
    }
}
