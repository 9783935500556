@import 'assets/scss/mixins';

.select {
    :global(.lang-select__control) {
        border: none;
        box-shadow: none;
        font-weight: 200 !important;
        font-family: $secondary-font;
        font-size: toRem(14);
        margin-top: -2px;

        &:hover {
            border-color: transparent;
            box-shadow: none;
        }
    }
    :global(.lang-select__indicators) {
        border: none;
    }
    :global(.lang-select__option) {
        cursor: pointer;
        color: $mainBlack;
        background-color: $mainWhite;
        font-size: toRem(14);
        line-height: toRem(22);
        padding: 8px 16px;

        &:active {
            color: $mainRed;
            background-color: $dark10;
        }
    }
    :global(.lang-select__option--is-focused) {
        background-color: $mainRed;
        color: $mainWhite;
    }
    :global(.lang-select__menu) {
        z-index: 100 !important;
        display: block;
    }
}
