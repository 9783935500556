@use 'sass:math';
@import './variables';

// convert px to rem
@function toRem($value) {
    $remValue: ($value / 16) + rem;
    @return $remValue;
}

@mixin font-face($font-name, $path, $weight: normal, $style: normal) {
    @font-face {
        font-family: quote($font-name);
        src: url($path + '.eot');
        src: url($path + '.eot?#iefix') format('embedded-opentype'), url($path + '.woff') format('woff'),
            url($path + '.ttf') format('truetype'), url($path + '.svg##{$font-name}') format('svg');
        font-weight: $weight;
        font-style: $style;
        font-display: swap;
    }
}

@mixin screen($breakpoint) {
    // If breakpoint exists in the variables array "variables.scss"
    @if map-has-key($breakpoints, $breakpoint) {
        // Prints a media query based on the value
        @media only screen and #{inspect(map-get($breakpoints, $breakpoint))} {
            @content;
        }
    }
    // If the key doesn't exist in the map
    @else {
        @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
    }
}

@mixin line-clamp($lines-to-show: 1) {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $lines-to-show;
}

@mixin truncate($truncation-boundary) {
    max-width: $truncation-boundary;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@mixin flex($align-items: center, $justify-content: center, $flex-direction: row, $gap: 0) {
    display: flex;
    flex-direction: $flex-direction;
    align-items: $align-items;
    justify-content: $justify-content;
    gap: $gap;
}

@mixin scrollbar($size, $bar, $background-color: mix($bar, white, 50%)) {
    // For Chrome
    &::-webkit-scrollbar {
        width: $size;
        height: $size;
    }

    &::-webkit-scrollbar-thumb {
        background: $bar;
        border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
        background: $background-color;
    }

    // For Internet Explorer
    & {
        scrollbar-face-color: $bar;
        scrollbar-track-color: $background-color;
    }
}
