@import 'assets/scss/mixins';

.root {
    background-color: $dark5;
    padding: 24px;
    border-radius: 4px;

    @include screen('mobile') {
        padding: 16px;
    }

    p {
        color: $dark80;
        font-family: $secondary-font;
        font-size: toRem(16);
        line-height: toRem(26);
    }
}

.main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    i {
        font-size: 24px;
        cursor: pointer;
    }
}

.wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    color: $dark1;
    font-family: $secondary-font;
    font-size: toRem(16);
    line-height: toRem(26);
}
