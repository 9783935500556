@import 'assets/scss/mixins';

.accordion {
    padding: 24px 0;
    border-radius: 4px;
    border: none !important;
}

.headingWrapper {
    padding: 0 32px;
    color: $dark5;
    font-size: toRem(16);
    line-height: toRem(24);
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include screen('mobile') {
        padding: 0 16px;
    }
}

.heading {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include screen('mobile') {
        &:nth-of-type(1) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    button {
        color: inherit;
        text-decoration: underline;
        display: inline-block;
    }

    i {
        transition: transform 300ms ease;
        display: inline-block;
    }

    &.expanded {
        i {
            transform: rotate(-180deg);
        }
    }
}

.showMore {
    display: none;
    @include screen('large-tablet') {
        display: block;
    }
}

.titleWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 4px;

    @include screen('large-tablet') {
        cursor: pointer;
    }

    h3 {
        text-transform: uppercase;
    }
}

.headerPrice {
    display: none;

    @include screen('large-tablet') {
        display: block;
    }

    .per_month {
        font: 500 14px/24px Mulish, san-serif;
        color: $dark20;
        margin-left: 4px;
        text-transform: lowercase;
    }
}

.content {
    padding: 0 32px;
    padding-right: 0;

    @include screen('mobile') {
        padding: 0 16px;
    }
}

.summary {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
    max-height: 460px;
    overflow: auto;
    @include scrollbar(4px, $dark80, transparent);
    padding-right: 16px;
    margin-right: 10px;

    h6 {
        font-size: toRem(14);
        line-height: toRem(20);

        @include screen('mobile') {
            font-size: toRem(12);
            line-height: toRem(16);
        }
    }
}

// .bike_wrapper {
//     display: flex;
//     border-bottom: 1px solid $darkClick;
//     justify-content: space-between;
// }

.edit {
    font-size: 14px;
    color: $dark20;
    font-weight: normal;
    font-family: $secondary-font;
    text-decoration: underline;
    margin-bottom: 12px;

    &:hover {
        text-decoration: none;
        cursor: pointer;
    }

    @include screen('large-tablet') {
        margin-bottom: 0;
    }
}

.parts {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-right: 14px;
    margin-right: -14px;

    @include scrollbar(4px, $dark80, transparent);
}

.part_section {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    border-bottom: 1px solid $darkClick;
    gap: 8px;

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }
}

.group {
    color: $dark60;
    font-size: toRem(14);
    line-height: toRem(20);

    @include screen('mobile') {
        font-size: toRem(12);
        line-height: toRem(16);
    }
}

.variant_wrapper {
    display: flex;
    gap: 12px;
}

.variant_name {
    @include line-clamp(1);
    flex: 1;
    color: $dark20;
}

.price {
    color: $dark60;
}

.divider {
    border-top: 1px dashed $dark80;
    position: relative;
    margin: 30px -32px 24px;

    @include screen('mobile') {
        margin: 22px -48px 22px -16px;
    }

    &::before {
        content: '';
        position: absolute;
        top: -6px;
        left: -7px;
        background-color: $dark20;
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }

    &::after {
        content: '';
        position: absolute;
        top: -6px;
        right: -7px;
        background-color: $dark20;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        z-index: 12;
    }
}

.footer {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding-right: 32px;

    h6 {
        font-size: toRem(14);
        line-height: toRem(20);

        @include screen('mobile') {
            font-size: toRem(12);
            line-height: toRem(16);
        }
    }
}

.footer_line {
    display: flex;
    justify-content: space-between;

    .footer_line_name {
        @include line-clamp(1);
        flex: 1;
    }

    h6 {
        color: $dark60;
    }
}

.total_price {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    .per_month {
        font: 500 14px/24px Mulish, san-serif;
        color: $dark20;
        margin-left: 4px;
        text-transform: lowercase;
    }

    h6 {
        color: $dark60;
    }

    h2 {
        color: $dark5;

        font-size: toRem(30);
        line-height: toRem(34);

        @include screen('mobile') {
            font-size: toRem(22);
            line-height: toRem(28);
        }
    }

    .include_taxes {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 16px;

        h6 {
            color: $dark20;
        }
    }
}
