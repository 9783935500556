@import 'assets/scss/mixins';

.home {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 0 80px;
    background-size: cover;

    @include screen('mobile') {
        padding: 40px 0;
        justify-content: center;
    }
}

.coverImage {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-size: cover;
    transition: all 150ms ease-in-out;
}

.title {
    margin-bottom: 80px;
    text-transform: uppercase;
    padding: 0 40px;
    text-align: center;
    transition: all 150ms ease-in-out;

    &.whiteText {
        color: $mainWhite;
    }

    @include screen('laptop') {
        max-width: 700px;
    }

    @include screen('large-tablet') {
        margin-bottom: 40px;
        max-width: 500px;
    }

    @include screen('mobile') {
        margin-bottom: 24px;
        max-width: 295px;
    }
}

.bike_categories {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;

    @include screen('large-tablet') {
        flex-direction: column;
        gap: 16px;
    }

    @media (max-width: 688px) {
        padding: 40px;
    }

    @include screen('mobile') {
        flex-direction: column;
        gap: 8px;
        padding: 0 40px;
        width: 100%;

        &:hover .category {
            opacity: 1;
        }
    }

    &:hover .category {
        opacity: 0.5;
    }
}

.category {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 170px;
    width: 170px;
    background-color: $dark5;
    transition: all 150ms ease-in;
    cursor: pointer;
    outline: none;
    border-radius: 4px;
    border: 1px solid white;
    user-select: none;

    @include screen('large-tablet') {
        height: 128px;
        width: 608px;
    }

    @include screen('tablet') {
        height: 128px;
        width: 608px;
    }

    // this is only used here, don't set it as variable
    @media (max-width: 640px) {
        height: 128px;
        width: 100%;
    }

    @include screen('mobile') {
        height: 88px;
        width: 100%;

        &:hover {
            background-color: $dark5;
            opacity: 1 !important;
        }
    }

    &:hover {
        background-color: $dark10;
        opacity: 1 !important;
    }

    &:active {
        border: 2px solid $mainRed;
    }

    p {
        text-align: center;
        max-width: 160px;

        @include screen('large-tablet') {
            max-width: 100%;
        }

        @include screen('mobile') {
            max-width: 100%;
        }
    }
}

// MOBILE CATEGORIES

.mobile_home {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-size: cover;
}
.backdrop {
    background: $mainBlack;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: -1;
    opacity: 0.2;
}
.bgImage {
    flex: 1;
    background-size: cover;
    background-position: center;
}
.mobile_categories {
    padding: 16px;
    background-color: $mainBackground;
    color: $mainWhite;

    h4 {
        padding: 0;
        margin-bottom: 16px;
        font-size: 22px;
        line-height: 28px;
        text-align: left;
        font-family: $primary-font;
    }
    .categories {
        display: flex;
        gap: 10px;
        overflow: scroll;
        padding: 0 16px 24px;
        margin: 0 -16px 24px;
    }
    .category_cell {
        background-color: $dark1;
        color: $dark40;
        padding: 14px 16px;
        border-radius: 24px;
        height: 48px;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        font-family: $secondary-font;
        flex-shrink: 0;
        border: 2px solid transparent;
        &.selected {
            color: $dark5;
            border-color: $mainRed;
        }
    }
    button {
        width: 100%;
    }
}
