@import 'assets/scss/mixins';

.bike3d__loading-screen {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 3;
    background: rgba(255, 255, 255, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1800ms opacity;
}

.opacity-0 {
    opacity: 0;
}

.fade-bg {
    background: rgba(0, 0, 0, 0.674);
}

.cursor-pointer {
    cursor: pointer;
}

.loading-screen__items {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 16px;
    height: 100%;
}

.controls-screen__group {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loading-screen__items-control {
    height: 3.2em;
    width: 3.2em;
}

.main-desc {
    font-size: 1.5em;
    color: white;
    white-space: pre;
    line-height: 0.9em;
}

.sub-desc {
    font-size: 1.1em;
    color: white;
    white-space: pre;
    line-height: 1.8em;
}
