@import 'assets/scss/mixins';

.modal {
    p {
        font-size: 14px;
        line-height: 22px;
        margin-top: 8px;
        color: $dark60;
    }

    b {
        font-weight: bold;
    }

    a {
        font-weight: bold;
        text-decoration: underline;
        color: $dark60;

        &:hover {
            text-decoration: none;
        }
    }

    .modalButton {
        width: 100%;
        margin-top: 24px;
        padding: 16px 24px;
    }
}
