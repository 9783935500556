@import 'src/assets/scss/mixins.scss';

.modal_overlay {
    width: 100vw;
    height: 100dvh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 900;
    position: fixed;
    transition: all 200ms ease-out;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    animation: darkenBG 300ms ease-in forwards;

    &.position {
        align-items: flex-end;
    }
}

.modal {
    padding: 48px;
    position: relative;
    width: 100%;
    max-height: 90dvh;
    height: auto;
    overflow: auto;
    background-color: $mainWhite;
    border-radius: 4px;
    opacity: 0;
    transition: all 0.4s ease-in-out;
    animation: slideUp 300ms ease-in forwards;

    &.dark {
        background-color: $mainBackground;
    }
}

.close_icon {
    cursor: pointer;
    z-index: 9;
    position: absolute;
    top: 24px;
    right: 24px;
    font-size: 24px !important;

    &.dark_icon {
        color: $dark5;
    }
}

@include screen('mobile') {
    .modal_overlay {
        align-items: flex-end;
    }
    .modal {
        padding: 16px 16px 32px;
        border-radius: 0;
    }
    .close_icon {
        top: 16px;
        right: 16px;
    }
}
//}

@keyframes slideUp {
    from {
        transform: translate(0, 300px);
        opacity: 0;
    }
    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}
@keyframes darkenBG {
    from {
        background-color: rgba($mainBackground, 0);
    }
    to {
        background-color: rgba($mainBackground, 0.5);
    }
}
