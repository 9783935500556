@import 'assets/scss/mixins';

.titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    i {
        cursor: pointer;
        background-color: $dark10;
        padding: 8px;
        border-radius: 50%;
        line-height: 0;
    }
}

.title {
    cursor: pointer;
    color: $dark1;
    font-weight: 500;
    text-transform: uppercase;
}
.subtitle {
    font-size: 14px;
    font-weight: 400;
    font-family: $secondary-font;
    line-height: 20px;
    color: $dark60;
    margin-top: 12px;
}
