@import 'assets/scss/mixins';

h1 {
    font-size: 3rem;
    line-height: 3.375rem;
    letter-spacing: 0.01rem;
    font-family: $primary-font;

    @include screen('mobile') {
        font-size: 2rem;
        line-height: 2.4375rem;
        letter-spacing: 0.03rem;
    }
}
h2 {
    font-size: 1.875rem;
    line-height: 2.125rem;
    letter-spacing: 0.02rem;
    font-family: $primary-font;

    @include screen('mobile') {
        font-size: 1.75rem;
        line-height: 2.125rem;
        letter-spacing: 0.03rem;
    }
}
h3 {
    font-size: 1.5rem;
    line-height: 1.875rem;
    letter-spacing: 0.03rem;
    font-family: $primary-font;

    @include screen('mobile') {
        font-size: 1.375rem;
        line-height: 1.75rem;
    }
}
h4 {
    font-size: 1.2543rem;
    line-height: 1.75rem;
    font-family: $secondary-font;

    @include screen('mobile') {
        font-size: 1.25rem;
        line-height: 1.5625rem;
    }
}
h5 {
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: $primary-font;
    letter-spacing: 0.04rem;

    @include screen('mobile') {
        font-size: 1.0625rem;
        line-height: 1.375rem;
    }
}

h6 {
    font-size: toRem(14);
    line-height: toRem(22);
    font-family: $secondary-font;
    letter-spacing: 0.01rem;
}

p {
    font-size: 1.25rem;
    line-height: 1.75rem;
    font-family: $secondary-font;

    @include screen('mobile') {
        font-size: 1.125rem;
        line-height: 1.625rem;
    }
}

::selection:not(img) {
    color: $mainWhite;
    background: $mainRed;
} 
