@import 'assets/scss/mixins';

.payments {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    label {
        color: white !important;
    }
}
