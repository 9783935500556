@import 'assets/scss/mixins';

.color_picker {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.title {
    color: $dark40;
    font-weight: 400;
    font-size: toRem(14);
    line-height: toRem(20);
    width: max-content;

    &.smaller {
        font-size: toRem(10);
        line-height: toRem(16);
    }
}

.color_list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;
    z-index: 15;
}

.skeleton_wrap {
    display: flex;
    gap: 4px;
    margin-top: 24px;
}
