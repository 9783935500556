@import 'assets/scss/mixins';

.notFound {
    text-align: center;
    display: flex;
    flex: 1;
    align-items: center;
    background: $dark5;

    .textWrapper {
        max-width: 800px;
        margin: 0 auto;
        padding: 0 20px;
        color: $dark1;
        text-transform: uppercase;
        h1 {
            margin-bottom: 48px;
        }
        .button {
            margin: 0 auto;
        }
    }
}
