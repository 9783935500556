@import 'assets/scss/mixins';

.quoteModal {
    h3 {
        text-transform: uppercase;
    }
    .quoteText {
        font-size: 14px;
        line-height: 22px;
        font-family: $secondary-font;
        margin-top: 8px;
        color: $dark60;
    }
    .quoteInputs {
        margin-top: 24px;
        > div {
            flex-direction: column;
            > div {
                max-width: unset;
            }
        }
        button {
            width: 100%;
        }
    }
}
