@import 'assets/scss/mixins';

.tags_wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 16px;
}

.tags {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 8px;

    @include screen('large-tablet') {
        flex-wrap: nowrap;
        overflow-y: auto;
        @include scrollbar(0px, transparent, transparent);
    }

    @include screen('mobile') {
        flex-wrap: nowrap;
        overflow-y: auto;
        @include scrollbar(0px, transparent, transparent);
    }
}

.tag {
    width: auto;
    background-color: $dark1;
    border-radius: 20px;
    padding: 0 16px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 2px solid transparent;
    transition: all 200ms ease-in;

    &.smaller {
        height: 30px;
        padding: 0 6px;

        .name {
            font-size: toRem(10);
            line-height: toRem(16);
        }
    }

    &.smallerFrame {
        width: 30px;
    }

    &:hover {
        background-color: $dark140;

        .name {
            color: $dark5;
        }
    }

    @include screen('large-tablet') {
        height: 64px;
        border-radius: 40px;
        padding: 0 32px;
    }

    @include screen('mobile') {
        height: 48px;
        border-radius: 24px;
        padding: 0 16px;
    }

    i {
        color: $dark40;
    }
}

.name {
    color: $dark40;
    font-weight: 400;
    font-size: toRem(14);
    line-height: toRem(20);
    width: max-content;
    transition: all 200ms ease-in;

    &.uppercase {
        text-transform: uppercase;
    }
}

.titleWrapper {
    display: flex;
    align-items: center;
    gap: 6px;
}

.title {
    @extend .name;

    &.smaller {
        font-size: toRem(10);
        line-height: toRem(16);
    }
}

.tooltipWrapper {
    i {
        transition: color 300ms ease-out;
        color: $dark40;
        font-size: 20px;
    }

    &:global(.active) {
        i {
            color: $mainAttention;
        }
    }
}

.tooltip {
    max-width: 300px;

    &.bottom {
        transform: translate(-50%, 10%);

        &:before {
            top: -13px;
            border-bottom-color: $mainWhite;
            border-top-color: unset;
        }

        &:after {
            top: -13px;
            border-bottom-color: $mainWhite;
            border-top-color: unset;
        }
    }

    b,
    p {
        font-size: toRem(14);
        line-height: toRem(20);
    }

    b {
        font-weight: 700;
    }

    p {
        margin-bottom: 0.5rem;
    }
}

.active_tag {
    border: 2px solid $mainRed;

    .name {
        color: $dark5;
    }
}

.rullerTag {
    width: 40px;
    padding: 0;

    &.smaller {
        height: 30px;
        width: 30px;

        i {
            font-size: 18px;
        }
    }
}
