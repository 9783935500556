@import 'assets/scss/mixins';

.bike_card {
    max-height: 174px;
    min-width: 0;
    background-color: $dark1;
    border-radius: 4px;
    padding: 12px;
    border: 2px solid transparent;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
    cursor: pointer;
    transition: all 200ms ease-in;

    &:hover {
        background-color: $dark140;
    }

    @include screen('large-tablet') {
        max-width: 151px;
        margin-bottom: 8px;
    }

    @include screen('small-tablet') {
        gap: 12px;
        min-width: 244px;
        flex-direction: row;
        padding: 12px;
        justify-content: center;
        align-items: center;
        margin-bottom: 8px;
    }
}

.bike_card_active {
    border: 2px solid $mainRed;
}

.light_mode {
    background-color: $dark5;

    &:hover {
        background-color: $dark10;
    }

    p {
        color: $dark1 !important;
        font-size: toRem(13);
    }

    .image {
        img {
            mix-blend-mode: normal;
            max-height: 70px;
        }
    }
}

.image {
    max-height: 80px;
    width: 100%;

    @include screen('small-tablet') {
        min-width: 0;
        width: 100%;
        max-height: 58px;
    }

    img {
        object-fit: cover;
        max-height: 100%;
        min-height: 67px;
        mix-blend-mode: screen;
    }

    @include screen('mobile') {
        img {
            object-fit: contain;
        }
    }
}

.thumbnail {
    position: relative;

    img {
        mix-blend-mode: screen;
    }

    .fallbackLogo {
        max-height: 80px;
        object-fit: cover;
        width: 120px;
        margin: 0 auto;
        display: block;
    }
}

.lightning {
    position: absolute;
    top: -4px;
    right: -4px;
    color: white;

    @include screen('small-tablet') {
        top: -12px;
        right: -6px;
    }
}

.name_price {
    width: 100%;

    p {
        font-size: toRem(14);
        line-height: toRem(20);
    }

    .name {
        @include line-clamp(2);
        display: block;
        color: $dark5;
    }

    .price {
        color: $dark40;
    }
}
