@import 'assets/scss/mixins';

.controls {
    position: absolute;
    top: 32px;
    right: 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 5;

    .control {
        width: 40px;
        height: 40px;
        background-color: $dark5;
        border-radius: 50%;
        border: 1px solid $dark10;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 150ms ease-in;

        @include screen('mobile') {
            display: none;

            &.reset {
                display: flex;
            }
        }

        img {
            width: 22px;
        }

        &:hover {
            cursor: pointer;
            background-color: $dark10;
        }
    }
    .reset i {
        font-size: 17px;
    }
    .left {
        transform: rotate(-90deg);
    }
    .right {
        transform: rotate(90deg);
    }
    .back {
        transform: rotate(180deg);
    }
}

@media (orientation: landscape) and (max-height: 499px) {
    .controls .control {
        display: none;
        &.reset {
            display: flex;
        }
    }
}
