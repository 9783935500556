@import 'assets/scss/mixins';

.body {
    padding: 24px 48px 48px;

    @include screen('mobile') {
        padding: 24px 16px 32px;
    }

    p {
        color: $dark60;
        font-size: toRem(14);
        line-height: toRem(22);
    }
}

.workingHours {
    margin-top: 16px;
    padding: 16px;
    background-color: $dark5;
    border-radius: 4px;
}

.subtitle {
    margin-top: 24px;
}

.radioOptions {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.highlightedText {
    color: $mainAttention;
}

.nextButton {
    margin-top: 40px;
    width: 100%;

    @include screen('mobile') {
        margin-top: 24px;
    }
}
