// font
$primary-font: 'Gentona', sans-serif;
$secondary-font: 'Mulish', sans-serif;

// MAIN COLORS
$mainRed: #db1812;
$mainWhite: #ffffff;
$mainBlack: #27252a;
$mainWarring: #f54522;
$mainAttention: #faa119;
$mainGreen: #40b25b;
// secondary colors
// DARK
$dark140: #0e0d0f;
$dark1: #181719;
$dark2: #28272a;
$dark3: #f8f7f9;
$darkClick: #3a383d;
$dark80: #4a484d;
$dark60: #727075;
$dark40: #a7a6a9;
$dark20: #d4d2d6;
$dark10: #e7e6ea;
$dark5: #f3f1f5;
// RED
$red110: #cd0600;
$red120: #ad130e;
$red50: #f09c99;
$red150: #7a1916;
// GREEN
$green110: #3aa253;
$green50: #9ed6ab;
// BACKGROUND
$mainBackground: #27252a;
$secondaryBackground: #27252a50;
// CUSTOM
$twinnerDark: #272825;

// Breakpoints
$breakpoints: (
    'small-mobile': (
        max-width: 330px,
    ),
    'mobile': (
        max-width: 499px,
    ),
    'small-tablet': (
        max-width: 768px,
    ),
    'tablet': (
        max-width: 834px,
    ),
    'large-tablet': (
        max-width: 1024px,
    ),
    'laptop': (
        max-width: 1366px,
    ),
    'small-desktop': (
        max-width: 1440px,
    ),
    'desktop': (
        max-width: 1680px,
    ),
    'large-desktop': (
        max-width: 1920px,
    ),
    '2k': (
        max-width: 2560px,
    ),
) !default;
