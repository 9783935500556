@import 'assets/scss/mixins';

.bike_carousel {
    z-index: 9;
    height: 170px;
    padding-left: 40px;
    margin-top: 32px;
    transition: opacity 800ms ease-in;

    &.loading {
        opacity: 0.3;
        transition: unset;
    }

    @include screen('mobile') {
        margin-top: 16px;
        height: auto;
        padding-left: 0;
        overflow-x: visible;
    }

    .skeleton {
        display: flex;
        gap: 8px;
        margin-top: 22px;
        width: fit-content;
    }
}

.carousel_categories {
    display: flex;
    align-items: flex-end;
    gap: 32px;
    height: 30px;

    @include screen('mobile') {
        height: 28px;
        width: 100%;
        overflow-y: hidden;
        overflow-x: auto;
        gap: 24px;
        @include scrollbar(0, transparent, transparent);
        align-items: flex-start;
    }
}

.category_name {
    position: relative;
    cursor: pointer;

    h3 {
        font-weight: 500;
        font-size: toRem(16);
        line-height: toRem(24);
        text-transform: uppercase;
        color: $dark60;
        transition: all 100ms ease-in;
        -webkit-transition: font-size 100ms ease-in;
    }

    @include screen('mobile') {
        display: flex;

        h3 {
            font-size: toRem(17);
            line-height: toRem(30);
            width: max-content;
        }
    }
}

.category_active {
    h3 {
        font-weight: 500;
        font-size: toRem(24);
        line-height: toRem(24);
        color: $dark1;

        @include screen('mobile') {
            font-size: toRem(20);
        }
    }
}

.category_progress {
    height: 2px;
    width: 100%;
    background-color: $dark10;
    border-radius: 4px;
    position: absolute;
    bottom: -4px;

    .progress {
        position: absolute;
        left: 0;
        height: 100%;
        background-color: $mainRed;
        transition: all 200ms ease-in;
        border-radius: 4px;
    }
}

.slider {
    //display: flex;
    //width: calc(100vw - 410px);
    margin-top: 24px;
    position: relative;

    :global {
        .slick-list {
            @include scrollbar(0px, transparent, transparent);
            overflow: visible;
            z-index: 0;
        }

        .slick-track {
            overflow: visible;
        }
    }

    @include screen('large-tablet') {
        width: 100%;
        margin-top: 16px;
    }
}

.left_arrow {
    transition: all 200ms ease-in;
    position: absolute;
    left: -25px;
    top: 32px;
    opacity: 0;
    z-index: 123;

    &.visible {
        opacity: 1;
    }
}

.right_arrow {
    @extend .left_arrow;
    left: unset;
    right: 25px;
}

.spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.divider {
    position: relative;
    width: 56px;
    background-color: $mainWhite;
    padding: 38px 27px;
    margin-right: 8px;
    border-radius: 4px;

    .line {
        width: 2px;
        height: 40px;
        background-color: $dark10;
    }
}
