@import 'assets/scss/mixins';

.form {
    width: 100%;
    max-width: 540px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    @include screen('mobile') {
        max-width: auto;
    }

    .input_wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .stripe_input {
        border: 1px solid $dark10;
        border-radius: 4px;
        max-width: 100%;
        box-sizing: border-box;
        color: $dark60;
        align-items: center;
        transition: all 150ms ease-in;
        padding: 16px;
        font-family: $secondary-font;
        font-size: 14px;
        line-height: 22px;
        height: 54px;

        &:hover {
            border-color: $dark40;
        }

        &.stripe_input_error {
            border: 1px solid $mainWarring;
        }
    }

    .input_wrapper_combined {
        @extend .input_wrapper;
        flex-direction: row;
        gap: 0;
    }

    .buttonsWrapper {
        @include screen('mobile') {
            margin-top: 8px;
            flex-direction: column;
        }
    }

    .button {
        min-width: 197px;
        padding: 16px 24px;
        font-size: toRem(14);
        line-height: toRem(20);
        flex: 1;

        @include screen('mobile') {
            max-width: 100%;
        }
    }
    .infoText {
        font-family: $secondary-font;
        font-size: toRem(14);
        line-height: toRem(20);
    }
}
