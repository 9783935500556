@import 'src/assets/scss/mixins.scss';

.root {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 20;
    background-color: $dark80;
    padding: 30px 25px 20px;
    color: $mainWhite;
    touch-action: none;

    @include screen('mobile') {
        left: 16px;
        right: 16px;
        bottom: 16px;
        padding: 25px 20px;
    }
}

.container {
    max-width: 1200px;
    margin: 0 auto;
}

.descriptionWrapper {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;

    @include screen('mobile') {
        flex-direction: column;
    }
}

.icon {
    font-size: 40px;
}

.description {
    font-size: 16px;
}

.checkboxWrapper {
    margin-left: 60px;
    display: flex;
    gap: 60px;

    @include screen('mobile') {
        margin-left: 0;
        flex-direction: column;
        gap: 20px;
    }
}

.policyWrapper {
    margin-left: 60px;
    margin-top: 20px;

    @include screen('mobile') {
        margin-left: 0;
    }
}

.policy {
    font-weight: 700;
    text-decoration: underline;
    font-size: 16px;
    color: $mainWhite;
}

.buttonWrapper {
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @include screen('mobile') {
        margin-top: 35px;
        justify-content: center;
    }
}
