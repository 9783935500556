@import 'assets/scss/mixins';

.tooltipWrapper {
    position: relative;
    cursor: pointer;
    line-height: 1;
}

.tooltip {
    z-index: 999;
    max-width: 220px;
    width: max-content;
    position: absolute;
    transform: translate(-50%, calc(-100% - 5px));
    background-color: $mainWhite;
    padding: 12px;
    border: 1px solid $mainAttention;
    border-radius: 4px;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border: 7px solid transparent;
        border-top-color: $mainAttention;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-top-color: $mainWhite;
    }

    .tooltipText {
        color: $dark1;
        font-family: $secondary-font;
        font-size: toRem(14);
        line-height: toRem(20);
    }
}
