@import 'assets/scss/mixins';

.instalmentPayment {
    p {
        font-size: 14px;
        color: $dark80;
    }
    b {
        font-weight: bold;
    }
    .button {
        margin-top: 24px;
        padding: 16px 24px;
    }
}

.options {
    display: flex;
    margin-top: 16px;
    margin-bottom: 24px;

    @include screen('small-tablet') {
        flex-direction: column;
    }
}
