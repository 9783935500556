@import 'assets/scss/mixins';

.email_modal {
    display: flex;
    flex-direction: column;

    .title {
        margin-bottom: 8px;
    }

    .subtitle {
        color: $dark60;
        letter-spacing: 0.14px;
        margin-bottom: 24px;

        strong {
            color: $dark1;
        }
    }

    .email_label {
        color: $dark1;
        margin-bottom: 12px;
    }

    .button {
        margin-top: 24px;
        width: 100%;
    }

    .checkbox_container {
        display: flex;
        gap: 12px;
        margin-top: 12px;
    }

    .checkbox_text {
        color: $dark60;
        font: 14px/16px Mulish, sans-serif;
        margin: 0;
    }
}
