@import 'assets/scss/mixins';

.contactButton {
    @include screen('mobile') {
        i {
            margin-right: 0 !important;
        }
    }
}
.modal {
    padding: 0;
    @include scrollbar(4px, $dark20, transparent);

    i {
        color: $mainWhite;
    }
}

.calendarModal {
    background-color: transparent;
    max-height: 100vh;

    @include screen('mobile') {
        background-color: $mainWhite;

        i {
            top: 24px;
            color: $dark1;
        }
    }
}

.successModal {
    i {
        color: $dark1;
    }
}

.contactCover {
    background: linear-gradient(86.62deg, rgba(24, 23, 25, 0.7) 0%, rgba(24, 23, 25, 0) 181.16%),
        url('/assets/images/contact_cover.png');
    min-height: 160px;

    display: flex;
    align-items: flex-end;
    padding: 24px 48px;

    h3 {
        color: $mainWhite;
        text-transform: uppercase;
        font-weight: 500;
    }
}

.body {
    padding: 24px 48px 48px;

    p {
        color: $dark60;
        font-size: toRem(14);
        line-height: toRem(22);
    }
}

.workingHours {
    margin-top: 16px;
    padding: 16px;
    background-color: $dark5;
    border-radius: 4px;
}

.subtitle {
    margin-top: 24px;
}

.radioOptions {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.nextButton {
    margin-top: 40px;
    width: 100%;
}
