@import 'assets/scss/mixins';

.bicycle_section {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    border-bottom: 1px solid $darkClick;
    gap: 8px;

    .title {
        color: $dark60;
        font-size: toRem(14);
        line-height: toRem(20);

        @include screen('mobile') {
            font-size: toRem(12);
            line-height: toRem(16);
        }
    }

    .line {
        display: flex;
        gap: 12px;
    }

    .name {
        @include line-clamp(1);
        flex: 1;
        color: $dark20;
    }

    .price {
        color: $dark60;
    }
}
