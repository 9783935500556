@import 'assets/scss/mixins';

.side_bar {
    //max-width: 362px;
    width: 100%;
    background-color: $mainBackground;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    position: relative;
    //z-index: 9;
    user-select: none;

    &.expand_sidebar {
        //max-width: 50%;
        min-width: 362px;
    }

    h5 {
        color: $dark5;
        text-transform: uppercase;
    }

    @include screen('large-tablet') {
        max-width: 100%;
        // min-height: 358px;
        max-height: 458px;
        overflow: visible;
    }

    @include screen('mobile') {
        max-width: 100%;
        // min-height: 250px;
        max-height: 330px;
    }

    &.checkout {
        max-width: 380px !important;
        height: fit-content;
        min-height: unset;
        border-radius: 4px;

        @include screen('large-tablet') {
            max-width: 100%;
            max-height: unset;
        }
    }
}

.resizable {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
    cursor: ew-resize;
    z-index: 22;
    //background-color: $dark1;
    border-left: 2px dashed $dark10;
}

// .mobile_tablet_wrapper {
//     min-height: 220px;

//     @include screen('mobile') {
//         min-height: 204px;
//     }
// }
