@import 'assets/scss/mixins';

.labelWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 4px;

    i {
        color: $mainAttention;
        font-size: 20px;
        cursor: pointer;
    }
}

.label {
    font-family: $secondary-font;
    color: $dark60;
    display: block;
    font-size: toRem(14);
    line-height: toRem(22);
}

.input {
    border: 1px solid $dark10;
    border-radius: 4px;
    max-width: 100%;
    display: flex;
    box-sizing: border-box;
    color: $dark60;
    align-items: center;
    transition: all 150ms ease-in;
    background: $mainWhite;

    input {
        padding: 16px;
        flex: 1;
        font-size: toRem(14);
        line-height: toRem(22);

        &::placeholder {
            color: $dark40;
        }
    }

    &:hover {
        border-color: $dark40;
    }

    &:focus-within {
        border-color: $dark1;
    }
}

.error {
    border: 1px solid $mainWarring;

    &:hover {
        border-color: $mainWarring;
    }

    &:focus-within {
        border-color: $mainWarring;
    }
}

.errorMessage {
    text-align: right;

    &.errorLeft {
        text-align: left;
    }
}

.suffix {
    padding-right: 16px;
}

@include screen('small-desktop') {
    .label {
        font-size: toRem(14);
    }
    .input {
        font-size: toRem(14);
    }
}
