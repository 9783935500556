@import 'assets/scss/mixins';

.overlay {
    align-items: flex-end;
}

.variants_popup {
    overflow: hidden;

    @include screen('large-tablet') {
        display: flex;
        flex-direction: column;
        padding: 24px 0 24px 32px;

        &.tablet_apple {
            margin-bottom: 80px;
        }
    }

    @include screen('mobile') {
        flex-direction: column;
        max-height: calc(100vh - 55px);
        height: auto;
        padding: 16px;
    }
}

.title {
    margin-bottom: 16px;
    text-transform: uppercase;

    @include screen('large-tablet') {
        font-size: toRem(16);
    }
    @include screen('mobile') {
        font-size: toRem(22);
    }
}

.variants_wrapper {
    display: flex;
    flex-direction: column;
}

.close_icon {
    top: 24px;
    right: 32px;
}

.variants {
    display: flex;
    gap: 8px;

    @include screen('large-tablet') {
        flex-direction: row;
        overflow-x: auto;
        padding-right: 32px;
    }

    @include screen('mobile') {
        flex-direction: column;
        padding: 0;
        max-height: 60vh;
    }
}

.footer {
    width: 100%;
    padding: 12px 16px;
    background-color: $mainWhite;

    @include screen('large-tablet') {
        padding: 24px 32px 0 0;
    }

    @include screen('mobile') {
        padding: 12px 0 0;
    }

    .button {
        width: 100%;
        max-height: 64px;
    }

    &.footer_iphone {
        bottom: 16px;
    }
}

.zoom_image_container_tablet {
    width: 100vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;

    .zoom_title {
        text-transform: uppercase;
    }

    .image_container {
        position: relative;
        padding: 24px;
    }

    img {
        width: 300px;
        height: 300px;
    }

    i {
        position: absolute;
        font-size: 10px;
    }

    .top_left {
        top: 8px;
        left: 8px;
    }

    .top_right {
        top: 8px;
        right: 8px;
        transform: rotate(90deg);
    }

    .bottom_left {
        bottom: 8px;
        left: 8px;
        transform: rotate(270deg);
    }

    .bottom_right {
        bottom: 8px;
        right: 8px;
        transform: rotate(180deg);
    }
}
