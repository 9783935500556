@import 'assets/scss/mixins';

.layout {
    display: flex;
    flex-direction: column;
    flex: 1;

    &.checkout {
        background-color: $dark3;
    }
}

.main {
    flex: 1;
    display: flex;
    flex-direction: column;
    //justify-content: center;
    //align-items: center;

    &.checkout {
        justify-content: flex-start;
    }
}
