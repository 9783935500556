@import 'assets/scss/mixins';

.root {
    width: 100%;
    min-height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px dashed $dark40;
    border-radius: 4px;

    @include screen('mobile') {
        min-height: 174px;
    }
}

.button {
    padding: 16px 24px;
    line-height: toRem(20);
}

.form {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.input {
    label {
        font-family: $secondary-font;
        color: $dark2;
    }
}

.labelWrapper {
    margin-bottom: 4px;
}

.cityWrapper {
    display: flex;
    gap: 16px;
}

.postalCode {
    flex: 1;
}

.city {
    flex: 2.5;
}

.modalButton {
    margin-left: auto;
    margin-top: 8px;
}
