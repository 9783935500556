*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    font-family: $primary-font;
    font-size: 16px;
    line-height: 26px;
    font-weight: normal;
    background-color: $mainWhite;
    color: $dark1;
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(#000, 0);
    -webkit-font-smoothing: initial;
    -moz-osx-font-smoothing: grayscale;
}

i[class^='icon-'],
i[class*=' icon-'] {
    line-height: 1;

    &:before {
        vertical-align: middle;
    }
}

img,
svg {
    vertical-align: middle;
}

img {
    max-width: 100%;
}

a {
    text-decoration: none;
}

button,
textarea,
input {
    outline: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-appearance: none;
    background-color: transparent;
    border-width: 0;
    font-size: 16px;
    font-weight: 400;
    border-radius: 8px;
    margin: 0;
    caret-color: $mainRed;

    &:focus {
        outline: none;
    }

    &::placeholder {
        opacity: 0.5;
    }
}

.react-skeleton-loader {
    display: flex;
    background: crimson;
}
