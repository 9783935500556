@import 'assets/scss/mixins';

.getAQuote {
    margin-top: 32px;
    display: flex;
    width: 380px;
    padding: 24px 32px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;
    border-radius: 4px;
    background: $dark10;
    font-family: $secondary-font;
    font-weight: 700;
    line-height: 22px;
    font-size: 14px;

    button {
        width: 100%;
        padding: 16px 24px;
    }
    b {
        color: $mainRed;
        display: inline;
    }
    a {
        text-decoration: underline;
        cursor: pointer;
    }
    a:hover {
        text-decoration: none;
    }

    @include screen('large-tablet') {
        flex-direction: row;
        width: 100%;
        margin-top: 16px;

        button {
            width: unset;
            text-wrap: nowrap;
        }
    }
    @include screen('mobile') {
        flex-direction: column;
        button {
            width: 100%;
        }
    }
}
