@import 'assets/scss/mixins';

.arButton {
    color: #27252a;
    width: 40px;
    height: 40px;
    padding: 8px;
    margin-top: 10px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(149, 149, 149, 0.681);
}
.loadingBox {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div {
        margin-top: 20px;
    }
    > button {
        margin-top: 16px;
        padding: 16px 24px;
    }
}
.qrBox {
    svg {
        padding: 20px 0;
    }
}
.bigArButton {
    position: absolute;
    bottom: 20px;
}
.modal {
    font-family: $secondary-font;

    .modalContent {
        padding: 20px 0;
        text-align: center;
    }
}
