@import 'assets/scss/mixins';

.upsell_item {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 24px 72px 24px 24px;
    background-color: #f8f7f9;
    border: 1px solid #d4d2d6;
    border-radius: 4px;
    position: relative;
    cursor: pointer;

    &.active {
        border: 1px solid $dark1;
    }

    &.no_desc {
        cursor: auto;
    }

    @include screen('mobile') {
        padding: 16px;
    }

    .wrap {
        display: grid;
        grid-template-rows: 0fr;

        transition: all 200ms ease-in;

        &.opened {
            grid-template-rows: 1fr;
            margin-top: 16px;

            @include screen('mobile') {
                margin-top: 12px;
                padding-bottom: 36px;
            }
        }
    }

    .desc {
        overflow: hidden;

        * {
            font: 400 16px/25px Mulish, sans-serif;
        }
    }

    .name_price_container {
        display: flex;
        justify-content: space-between;

        @include screen('mobile') {
            flex-direction: column;
        }
    }

    .name_price_none {
        width: 100%;
        display: flex;
        justify-content: space-between;

        @include screen('mobile') {
            flex-direction: column;
        }
    }

    .name_wrapper {
        display: flex;
        gap: 16px;

        @include screen('mobile') {
            flex-direction: column;
            gap: 8px;
        }
    }

    .empty_section {
        @extend .name_wrapper;
    }

    .name {
        font: 500 16px/24px Gentona, sans-serif;
        letter-spacing: 0.64px;
        text-transform: uppercase;
    }

    .price {
        font: 400 16px/26px Mulish, sans-serif;
        display: flex;

        .discount {
            color: $dark40;
            text-decoration: line-through;
            margin-right: 16px;
        }

        @include screen('mobile') {
            margin-top: 12px;
        }

        &.mobile_price {
            flex-direction: row-reverse;
            justify-content: flex-end;

            .discount {
                margin-left: 16px;
            }
        }
    }

    .arrow_container {
        position: unset;

        @include screen('mobile') {
            position: absolute;
            display: flex;
            align-items: center;
            right: 16px;
            bottom: 18px;
        }

        .more {
            @include screen('mobile') {
                font: 500 14px/20px Gentona, sans-serif;
                text-transform: uppercase;
                letter-spacing: 1.4px;
                text-decoration: underline;
            }
        }
    }

    .arrow {
        position: absolute;
        right: 24px;
        transition: all 200ms ease-in-out;

        @include screen('mobile') {
            position: unset;
        }

        &.rotate {
            transform: rotate(180deg);
        }
    }

    .item_header {
        display: flex;
    }
}

.upsell_item_empty {
    @extend .upsell_item;
    cursor: auto;
}
