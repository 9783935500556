@import 'assets/scss/mixins';

.root {
    padding: 48px;

    @include screen('mobile') {
        padding: 16px 16px 40px;
    }

    i {
        color: $mainGreen !important;
        font-size: 80px;

        @include screen('mobile') {
            font-size: 60px;
        }
    }

    h3 {
        margin-top: 24px;
        text-transform: uppercase;
    }

    p {
        margin-top: 16px;
        color: $dark60;
        font-size: toRem(14);
        line-height: toRem(22);
    }
}
