@import 'assets/scss/mixins';

.label {
    margin-bottom: 12px;
}

.pickup {
    margin-top: 24px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;

    @include screen('mobile') {
        grid-template-columns: 1fr;
        gap: 24px;
    }
}

.pickupDetails {
    margin-top: 16px;

    .pickupDetail {
        display: flex;
        gap: 16px;

        p {
            color: $dark80;
            font-size: toRem(16);
            line-height: toRem(26);
        }

        span {
            color: $dark40;
            min-width: 81px;
            font-size: toRem(16);
            line-height: toRem(26);
            text-transform: capitalize;
        }
    }
}

.map {
    border-radius: 4px;
}
