@import 'assets/scss/mixins';

.rich_text {
    font-size: toRem(16);
    color: $dark80;

    b {
        font-weight: 700;
    }

    i {
        font-style: italic;
    }

    ol {
        list-style: decimal;
        padding-left: 20px;
    }

    .product_variant_desc {
        display: inline;
    }

    .read_more {
        text-decoration: underline;
        margin-left: 5px;
        font-style: normal;
        font-family: $secondary-font;
        font-size: toRem(14);
        transition: all 150ms ease-in-out;
        &:hover {
            color: $mainRed;
        }
    }

    @include screen('large-tablet') {
        max-width: 537px;
    }

    @include screen('tablet') {
        max-width: 400px;
    }

    @include screen('mobile') {
        font-size: toRem(14);
        line-height: toRem(22);
    }
}
