@import 'assets/scss/mixins';

.metadata_section {
    display: flex;
    flex-direction: column;
    padding: 12px 0;
    border-bottom: 1px solid #3a383d;
    gap: 8px;
    border-top: 1px solid #3a383d;

    .metadata_line {
        display: flex;
        justify-content: space-between;

        .key {
            @include line-clamp(1);
            flex: 1;
            color: $dark20;
        }

        .value {
            color: $dark60;
            text-transform: capitalize;
        }

        .color_wrapper {
            display: flex;
            gap: 8px;

            .color {
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid #000000;
            }
        }
    }
}
