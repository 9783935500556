@import 'assets/scss/mixins';

.summary_drawer {
    position: absolute;
    top: 0;
    background-color: $mainBackground;
    width: 100%;
    z-index: 14;
    padding-top: 60px;
    animation: slideDown 0.4s forwards;
    visibility: hidden;
    border-top: 1px solid $darkClick;

    .scroll_wrapper {
        height: 100%;
        width: 100%;
        overflow: auto;
        position: relative;
        @include scrollbar(4px, $dark80, transparent);
        padding-right: 16px;
    }

    &.opened {
        animation: slideUp 0.5s forwards;
        visibility: visible;
    }

    @include screen('large-tablet') {
        height: 450px;
        position: fixed;
        top: unset;
        bottom: 144px;
        padding: 32px 32px 20px;
    }

    @include screen('mobile') {
        padding: 16px;
        bottom: 127px;
    }

    .selected_parts {
        display: flex;
        flex-direction: column;
        margin-top: 12px;
    }

    .part_section {
        display: flex;
        flex-direction: column;
        gap: 8px;
        border-top: 1px solid $darkClick;
        padding: 12px 0;
    }

    .highlighted {
        display: flex;
        justify-content: space-between;
        color: $dark20;

        h6 {
            &:nth-of-type(2) {
                color: $dark60;
            }
        }
    }

    .part {
        display: flex;
        justify-content: space-between;
    }

    .group {
        color: $dark60;
    }

    .variant {
        color: $dark20;
        @include line-clamp(1);
        flex: 1;
    }

    .price {
        color: $dark60;
    }

    .title_wrapper {
        @include screen('large-tablet') {
            display: flex;
            width: 100%;
            justify-content: space-between;
        }
    }

    .hide_summary {
        padding: 6px 12px;
        border: 1px solid $dark80;
        background-color: $darkClick;
        border-radius: 20px;
        display: flex;
        gap: 5px;
        cursor: pointer;
        color: $dark20;
        z-index: 15;
        position: absolute;
        top: 25px;
        left: 50%;
        transform: translate(-50%, -50%);
        width: max-content;

        h6 {
            font-size: toRem(14);
            line-height: toRem(20);
        }

        .icon {
            font-size: 20px;
        }

        @include screen('large-tablet') {
            position: relative;
            top: unset;
            left: unset;
            transform: unset;
            border-radius: unset;
            padding: unset;
            border: unset;
            background-color: unset;

            h6 {
                text-decoration: underline;
            }
        }
    }
}

@keyframes slideUp {
    from {
        transform: translateY(100%);
    }
    to {
        transform: translateY(0%);
    }
}

@keyframes slideDown {
    from {
        transform: translateY(0);
        visibility: visible;
        opacity: 1;
    }
    to {
        transform: translateY(100%);
        visibility: hidden;
        opacity: 0;
    }
}
