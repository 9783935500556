.arBox {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    display: relative;
    height: 100%;
}

.btn {
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: 12px 50%;
    background-color: #fff;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    bottom: 20px;
    padding: 0px 16px;
    font-size: 14px;
    color: #4285f4;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
    border: 1px solid #dadce0;

    i {
        margin-right: 10px;
    }
}

.btn:active {
    background-color: #e8eaed;
}

.btn:focus {
    outline: none;
}

.btn:focus-visible {
    outline: 1px solid #4285f4;
}
