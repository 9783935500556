@import 'src/assets/scss/mixins.scss';

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: $primary-font;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    line-height: 22px;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    user-select: none;
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    padding: 22px 40px;
    border-radius: 4px;

    i {
        font-size: 24px !important;
    }

    &.isDisabled,
    &:disabled {
        pointer-events: none;
        opacity: 0.6;
        cursor: not-allowed;

        &:hover,
        &:active {
            background-color: inherit;
        }
    }
}

.preIcon {
    margin-right: 12px;
}

.postIcon {
    margin-left: 12px;
}

.primary {
    background-color: $mainRed;
    color: $mainWhite;

    &:hover {
        background-color: $red110;
    }

    &:active {
        background-color: $red120;
    }
}

.primaryDark {
    @extend .primary;
    background-color: $darkClick;
    color: $dark5;

    &:hover {
        color: $mainWhite;
    }

    &:disabled {
        color: $dark40;
    }
}

.secondary {
    color: $dark1;
    padding: 22px 24px;
    background-color: $dark5;
    font-weight: 400;
    text-transform: unset;
    letter-spacing: 0.01em;
    font-family: $secondary-font;

    &:hover {
        background-color: $dark10;
    }

    &:active {
        background-color: $dark20;
    }
}

.ghost {
    font-weight: 500;
    border: 2px solid $dark1;
    background: $mainWhite;
    color: $dark1;

    &:hover {
        color: $dark80;
        border: 2px solid $dark80;
    }

    &:active {
        color: $dark60;
        border: 2px solid $dark60;
    }

    &:disabled {
        color: $dark40;
        border-color: $dark40;
    }
}

.text {
    font-family: $secondary-font;
    letter-spacing: 0.01rem;
    text-transform: unset;
    padding: 0;
    width: auto;
    color: $dark1;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
        margin: 0;
        border-color: $mainWhite $mainWhite $mainWhite transparent;
        width: toRem(24);
        height: toRem(24);
    }

    &.darkLoader {
        &:after {
            border-color: $dark1 $dark1 $dark1 transparent;
        }
    }
}

@include screen('mobile') {
    .ghost {
        font-size: toRem(16);
    }
}
