@import 'assets/scss/mixins';

.loader {
    display: inline-block;
    width: toRem(30);
    height: toRem(30);
}

.loader:after {
    content: ' ';
    display: block;
    width: toRem(30);
    height: toRem(30);
    border-radius: 50%;
    border: 3px solid $mainRed;
    border-color: $mainRed transparent $mainRed transparent;
    animation: loader 1.2s linear infinite;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
