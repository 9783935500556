$icons-font: "icons";

@font-face {
    font-family: $icons-font;
    src: url("../fonts/icon/icons.eot?fd4b8965540bad2c6835db53d3bd743e#iefix") format("embedded-opentype"),
url("../fonts/icon/icons.woff2?fd4b8965540bad2c6835db53d3bd743e") format("woff2"),
url("../fonts/icon/icons.woff?fd4b8965540bad2c6835db53d3bd743e") format("woff");
}

i[class^="icon-"]:before, i[class*=" icon-"]:before {
    font-family: icons !important;
    font-style: normal;
    font-weight: normal !important;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

$icons-map: (
    "ar": "\f101",
    "arrow_alt": "\f102",
    "arrow_down": "\f103",
    "arrow_left": "\f104",
    "arrow_right": "\f105",
    "cart": "\f106",
    "check_circle": "\f107",
    "check": "\f108",
    "checkmark": "\f109",
    "close_small": "\f10a",
    "close": "\f10b",
    "controls": "\f10c",
    "copy-bold": "\f10d",
    "copy-text": "\f10e",
    "copy": "\f10f",
    "edge": "\f110",
    "edit": "\f111",
    "error": "\f112",
    "expand": "\f113",
    "external": "\f114",
    "gift": "\f115",
    "glossy": "\f116",
    "lightning": "\f117",
    "lock": "\f118",
    "mail": "\f119",
    "matte": "\f11a",
    "mob_check": "\f11b",
    "mob_close": "\f11c",
    "mob_color": "\f11d",
    "mob_copy": "\f11e",
    "mob_export": "\f11f",
    "offer": "\f120",
    "palette": "\f121",
    "question": "\f122",
    "reset": "\f123",
    "ruler": "\f124",
    "send": "\f125",
    "share-bold": "\f126",
    "share": "\f127",
    "star": "\f128",
    "support-bold": "\f129",
    "support": "\f12a",
    "tablet_color": "\f12b",
    "tablet_export": "\f12c",
    "upload": "\f12d",
    "weight": "\f12e",
);

.icon-ar:before {
    content: map-get($icons-map, "ar");
}
.icon-arrow_alt:before {
    content: map-get($icons-map, "arrow_alt");
}
.icon-arrow_down:before {
    content: map-get($icons-map, "arrow_down");
}
.icon-arrow_left:before {
    content: map-get($icons-map, "arrow_left");
}
.icon-arrow_right:before {
    content: map-get($icons-map, "arrow_right");
}
.icon-cart:before {
    content: map-get($icons-map, "cart");
}
.icon-check_circle:before {
    content: map-get($icons-map, "check_circle");
}
.icon-check:before {
    content: map-get($icons-map, "check");
}
.icon-checkmark:before {
    content: map-get($icons-map, "checkmark");
}
.icon-close_small:before {
    content: map-get($icons-map, "close_small");
}
.icon-close:before {
    content: map-get($icons-map, "close");
}
.icon-controls:before {
    content: map-get($icons-map, "controls");
}
.icon-copy-bold:before {
    content: map-get($icons-map, "copy-bold");
}
.icon-copy-text:before {
    content: map-get($icons-map, "copy-text");
}
.icon-copy:before {
    content: map-get($icons-map, "copy");
}
.icon-edge:before {
    content: map-get($icons-map, "edge");
}
.icon-edit:before {
    content: map-get($icons-map, "edit");
}
.icon-error:before {
    content: map-get($icons-map, "error");
}
.icon-expand:before {
    content: map-get($icons-map, "expand");
}
.icon-external:before {
    content: map-get($icons-map, "external");
}
.icon-gift:before {
    content: map-get($icons-map, "gift");
}
.icon-glossy:before {
    content: map-get($icons-map, "glossy");
}
.icon-lightning:before {
    content: map-get($icons-map, "lightning");
}
.icon-lock:before {
    content: map-get($icons-map, "lock");
}
.icon-mail:before {
    content: map-get($icons-map, "mail");
}
.icon-matte:before {
    content: map-get($icons-map, "matte");
}
.icon-mob_check:before {
    content: map-get($icons-map, "mob_check");
}
.icon-mob_close:before {
    content: map-get($icons-map, "mob_close");
}
.icon-mob_color:before {
    content: map-get($icons-map, "mob_color");
}
.icon-mob_copy:before {
    content: map-get($icons-map, "mob_copy");
}
.icon-mob_export:before {
    content: map-get($icons-map, "mob_export");
}
.icon-offer:before {
    content: map-get($icons-map, "offer");
}
.icon-palette:before {
    content: map-get($icons-map, "palette");
}
.icon-question:before {
    content: map-get($icons-map, "question");
}
.icon-reset:before {
    content: map-get($icons-map, "reset");
}
.icon-ruler:before {
    content: map-get($icons-map, "ruler");
}
.icon-send:before {
    content: map-get($icons-map, "send");
}
.icon-share-bold:before {
    content: map-get($icons-map, "share-bold");
}
.icon-share:before {
    content: map-get($icons-map, "share");
}
.icon-star:before {
    content: map-get($icons-map, "star");
}
.icon-support-bold:before {
    content: map-get($icons-map, "support-bold");
}
.icon-support:before {
    content: map-get($icons-map, "support");
}
.icon-tablet_color:before {
    content: map-get($icons-map, "tablet_color");
}
.icon-tablet_export:before {
    content: map-get($icons-map, "tablet_export");
}
.icon-upload:before {
    content: map-get($icons-map, "upload");
}
.icon-weight:before {
    content: map-get($icons-map, "weight");
}
