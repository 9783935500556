@import 'assets/scss/mixins';

.fileUpload {
    margin-top: 32px;

    .dropZone {
        padding: 24px 16px;
        border: 1px dashed $dark40;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        color: $dark1;
        gap: 8px;
        line-height: 20px;
        margin-bottom: 12px;
        position: relative;
        cursor: pointer;

        .title {
            color: $dark60;
            font-size: 14px;
            font-family: $secondary-font;
            line-height: 22px;
            font-weight: 400;
        }
    }

    .loader {
        position: absolute;
        background: rgba($dark20, 0.25);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
    }

    .files {
        display: flex;
        gap: 8px;
    }
    .fileTag {
        padding: 10px 12px 10px 16px;
        font-size: 14px;
        line-height: 20px;
        font-family: $secondary-font;
        background: $dark5;
        border-radius: 20px;
        display: flex;
        gap: 8px;
        transition: all 150ms ease-in-out;
        animation: scaleIn 150ms ease-in-out forwards;
        overflow: hidden;

        i {
            font-size: 20px;
        }
        &:hover {
            background: $dark20;
            i {
                cursor: pointer;
            }
        }
    }
    .notSupported {
        color: $red110;
        font-size: 14px;
        font-family: $secondary-font;
        margin: -12px 0 12px;

        span {
            color: $dark1;
        }
    }
}

@keyframes scaleIn {
    from {
        opacity: 0;
        transform: scale(0);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}
