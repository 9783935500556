@import 'assets/scss/mixins';

.configurator {
    padding: 32px 24px;
    height: 100%;
    overflow: auto;
    //overflow-y: hidden;
    z-index: 12;
    @include scrollbar(4px, $dark80, transparent);
    position: relative;

    &.overflow_hidden {
        overflow-y: hidden;
    }

    &.smaller {
        padding: 16px;
    }
}

.sidebar_main {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    transition: flex-wrap 300ms ease-in;
    flex-direction: column;
    flex-wrap: unset;

    &.wrap {
        flex-wrap: wrap;
        flex-direction: row;
    }
}

.preconig_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
}

.summary {
    padding: 6px 12px;
    border: 1px solid $dark80;
    background-color: $darkClick;
    border-radius: 20px;
    display: flex;
    cursor: pointer;
    color: $dark20;
    z-index: 15;
    position: absolute;
    top: unset;
    bottom: 195px;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: fadeIn 500ms forwards;
    width: max-content;

    h6 {
        line-height: 24px;
    }

    .icon {
        transform: rotate(180deg);
    }

    &.hide {
        display: none;
        opacity: 0;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.skeleton {
    display: flex;
    gap: 4px;
    margin-top: 50px;
    flex-wrap: wrap;
}

.configurator_footer {
    padding: 38px 24px 24px 32px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    color: $mainWhite;
    border-top: 1px dashed $dark80;
    position: relative;
    z-index: 14;
    background-color: $mainBackground;

    &.smaller {
        padding: 16px;
    }

    &::before {
        content: '';
        position: absolute;
        top: -6px;
        left: -7px;
        background-color: $dark20;
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }

    &::after {
        content: '';
        position: absolute;
        top: -6px;
        right: -7px;
        background-color: $dark20;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        z-index: 12;
    }

    .button {
        width: 100%;
        margin-top: 20px;
        max-height: 64px;
        padding: 22px 0;

        .btn_loader {
            margin-left: 10px;

            &:after {
                width: toRem(22);
                height: toRem(22);
            }
        }
    }

    .price_from_wrapper {
        display: flex;
        margin-bottom: 2px;

        .question_mark {
            margin-left: 4px;
            font-size: 18px;
            cursor: pointer;
        }
    }
}

.per_month {
    font: 500 14px/24px Mulish, san-serif;
    color: $dark20;
    margin-left: 4px;
}

.total_price {
    font-size: 14px;
    line-height: 20px;
    color: #ffffff60;
}

.price {
    color: $dark5;
    display: flex;
    align-items: flex-end;
}

.bikeSizes {
    :global(.tag) {
        width: 40px;
    }
}

.include_taxes {
    color: $dark20;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;

    h3 {
        font-size: 14px;
        font-family: $secondary-font;
        font-weight: 600;
    }
}

//.summary_drawer {
//    height: 100%;
//}
