@import 'assets/scss/mixins';

.accordion {
    border: 1px solid $dark20;
    border-radius: 4px;
    padding: 24px 32px;
    // overflow: hidden;

    @include screen('mobile') {
        padding: 24px 16px;
    }

    &.expanded {
        border-color: $dark1;
    }
}

.content {
    transition: height 0.3s ease-in-out;
}
