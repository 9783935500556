@import 'assets/scss/mixins';

.bike_model {
    padding: 32px 24px 24px;
    height: 100%;
    overflow: auto;
    @include scrollbar(4px, $dark80, transparent);

    .title {
        margin-bottom: 12px;
    }

    .description {
        margin-bottom: 16px;
        color: $dark40;
        font-size: toRem(14);
        line-height: toRem(20);
    }

    @include screen('large-tablet') {
        padding: 24px 32px;
        overflow: unset;
    }

    @include screen('small-tablet') {
        padding: 16px 0 16px 16px;
        max-height: 332px;

        .description {
            font-size: toRem(12);
            line-height: toRem(16);
            margin-bottom: 8px;
        }
    }
}

.spinner {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bike_model_footer {
    width: 100%;
    border-top: 1px solid $darkClick;
    padding: 8px 24px 24px;

    @include screen('small-tablet') {
        border: none;
        padding: 0 16px 16px;
    }

    button {
        width: 100%;
        padding: 22px;

        @include screen('small-tablet') {
            height: 64px;
        }
    }
}

.bike_models {
    margin-top: 16px;
    display: grid;
    //grid-template-columns: 1fr 1fr;
    gap: 8px;
    grid-template-columns: repeat(auto-fill, minmax(145px, 1fr));

    @include screen('large-tablet') {
        @include scrollbar(4px, $dark80, transparent);
        overflow-y: auto;
        width: 100%;
        grid-template-columns: none;
        grid-auto-flow: column;
        grid-auto-columns: 153px;
    }

    @include screen('small-tablet') {
        @include scrollbar(4px, $dark80, transparent);
        grid-auto-columns: min-content;
        margin-top: 8px;
        margin-left: -16px;
        width: calc(100% + 32px);
        padding: 0 16px;
    }
}
