@import 'assets/scss/mixins';

.service_upgrade {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;
    background-color: #f3f1f5;
    padding-bottom: 78px;

    @include screen('large-tablet') {
        padding-bottom: 0;
        gap: 0;
    }

    .services {
        max-width: 1180px;
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 40px;

        @include screen('large-tablet') {
            padding: 0 80px 80px;
        }

        @include screen('tablet') {
            padding: 0 40px 40px;
        }

        @include screen('mobile') {
            padding: 40px 16px 56px;
        }
    }

    .title_section {
        margin-bottom: 8px;
        text-transform: uppercase;
    }

    .service_section {
        display: flex;
        flex-direction: column;
    }

    .services_items {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-top: 24px;
    }

    .service_description {
        @include screen('large-tablet') {
            max-width: 100%;
        }

        p {
            font: 400 16px/26px Mulish, sans-serif;
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        position: sticky;
        bottom: 0;
        width: 1180px;
        background-color: $dark5;
        padding: 24px;

        @include screen('large-tablet') {
            width: 100%;
        }
    }

    .button {
        width: auto;
        max-height: 54px;

        @include screen('large-tablet') {
            width: 100%;
        }
    }
}

.loader_container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.waiting {
    @extend .loader_container;
    position: fixed;
    backdrop-filter: blur(5px);
    z-index: 2;
}
