@import 'assets/scss/mixins';

.loader {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.no_products {
    @extend .loader;
    padding: 0 32px;
    text-align: center;
}

.bike_model {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 80px);

    @include screen('large-tablet') {
        height: auto;
        // min-height: calc(100vh - 80px);
        flex-direction: column;
        flex: 1;
    }

    @include screen('small-tablet') {
        // min-height: calc(100vh - 56px);
        flex: 1;
    }
}

.main_container {
    // height: 100%;
    padding: 32px 40px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;

    @include screen('large-tablet') {
        padding: 24px 32px;
        gap: 30px;
    }

    @include screen('small-tablet') {
        padding: 16px;
        gap: 16px;
        // min-height: 380px;
        overflow: auto;
    }
}

.title {
    text-transform: uppercase;
    align-self: flex-start;
}

.imageWrapper {
    height: 100%;
    flex: 1;
    display: flex;
}

.bikeImage {
    overflow: auto;
    flex: 1;
    // min-height: 140px;
    max-height: 650px;
}

.bike_description {
    display: flex;
    gap: 40px;

    @include screen('large-tablet') {
        gap: 34px;
    }

    @include screen('small-tablet') {
        flex-direction: column;
        gap: 18px;
    }
}

.bike_glance {
    flex: 2;
    max-width: 540px;
    line-height: toRem(14);

    p {
        font-size: toRem(16);
        line-height: toRem(26);
    }

    h3 {
        text-transform: uppercase;
        margin-bottom: 16px;

        @include screen('large-tablet') {
            margin-bottom: 16px;
        }

        @include screen('small-tablet') {
            margin-bottom: 8px;
            font-size: toRem(16);
        }
    }
}

.mobile_desc {
    p {
        display: inline;
        font-size: toRem(12);
        line-height: toRem(20);
    }
}

.show_more {
    font-size: toRem(12);
    line-height: toRem(20);
    font-weight: 700;
    color: $dark60;
    margin-left: 10px;
}

.specifications {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-self: flex-end;

    @include screen('small-tablet') {
        align-self: flex-start;
        margin-top: 8px;
    }
}

.spec {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    p {
        font-size: toRem(16);
        line-height: 26px;
        margin-left: 8px;
        font-family: $primary-font;
    }

    i {
        color: $dark40;
        font-size: toRem(16);
        vertical-align: middle;
    }
    @include screen('small-tablet') {
        i {
            margin-top: 0;
        }
        p {
            font-size: toRem(14);
            line-height: toRem(20);
        }
    }
}
