@import 'assets/scss/mixins';

.tabs {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.tab {
    padding: 16px 14px;
    background: $dark5;
    display: flex;
    gap: 12px;
    align-items: center;
    border-radius: 4px;
    font-family: $secondary-font;

    &:hover {
        cursor: pointer;
    }
}
