@import 'assets/scss/mixins';

.root {
    :global .react-datepicker-wrapper {
        margin-top: 4px;
        width: 100%;
    }

    h3 {
        margin-top: 32px;
    }

    h4 {
        margin-top: 24px;
        font-size: toRem(16);
        line-height: toRem(24);
    }

    h3,
    h4 {
        font-family: $primary-font;
        font-weight: 500;
        text-transform: uppercase;
    }

    label {
        margin-top: 16px;
        color: $dark60;
    }
}

.row {
    display: flex;
    gap: 16px;
    align-items: flex-end;

    > div {
        flex: 1;
    }

    @include screen('mobile') {
        flex-direction: column;
        gap: 0;
        align-items: unset;
    }

    .remove_voucher {
        cursor: pointer;
        margin-bottom: 12px;
    }
}

.discount_wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 16px;

    @include screen('mobile') {
        flex-direction: column;
        align-items: unset;
    }

    .input_discount_wrapper {
        display: flex;
        flex-direction: column;
        gap: 6px;
        width: 323px;

        @include screen('mobile') {
            width: 100%;
        }

        .fields {
            display: flex;
            gap: 12px;
            align-items: flex-end;
        }

        .input_discount {
            flex: 1;

            input {
                width: 100%;
            }
        }

        .discount_type {
            width: 92px;
        }
    }

    .apply_discount_btn {
        max-height: 56px;
        justify-self: flex-end;
        transition: unset;

        &.has_error {
            margin-bottom: 36px;

            @include screen('mobile') {
                margin-bottom: 0;
            }
        }
    }
}

.deposit_box {
    display: flex;
    flex-direction: column;
    max-width: 339px;

    .error {
        text-align: left;
    }
}

.accordion {
    margin-top: 24px;
    padding: 16px;

    h4 {
        margin: 0;
        cursor: pointer;
    }

    i {
        display: inline-block;
        transition: transform 300ms ease;
    }

    &.expanded {
        i {
            transform: rotate(180deg);
        }
    }
}

.input {
    background-color: $mainWhite;
}

.selectLabelWrapper {
    margin-bottom: 8px;
}

.errorMessage {
    text-align: left;
}

.actions {
    margin-top: 32px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include screen('mobile') {
        margin-top: 24px;
    }
}

.button {
    // margin-top: 32px;
    // margin-left: auto;
    padding: 16px 24px;

    @include screen('mobile') {
        // margin-top: 24px;
        margin-left: unset;
        width: 100%;
    }
}

.customCharge {
    .amount {
        display: flex;
        margin-bottom: 32px;
    }
}
